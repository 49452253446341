<template>
  <v-card class="mr-5">
    <v-card-title>
      アクセス権限管理
      <v-btn color="info" class="pa-2 ml-6" @click="startEdit">追加</v-btn>

      <v-spacer />
      <!-- ロール検索フィールド -->
      <v-text-field v-model="search" append-icon="mdi-magnify" label="ロール検索" single-line hide-details />
    </v-card-title>
    <!-- アクセス権限リスト表示テーブル -->
    <v-data-table
      :headers="getHeaders()"
      :items="listitem"
      item-key="id"
      :search="search"
      class="ma-4"
      :mobile-breakpoint="0"
      @click:row="showEvent"
    >
      <template v-slot:[`item.is_default`]="{ item }">
        {{ item.is_default ? 'する' : 'しない' }}
      </template>
      <template v-slot:[`item.me_action`]="{ item }">
        {{ dispActionData(item.me_action) }}
      </template>
      <template v-slot:[`item.other_action`]="{ item }">
        {{ dispActionData(item.other_action) }}
      </template>
      <template v-slot:[`item.updateuser`]="{ item }">
        {{ getUserName(item.updateuser) }}
      </template>
      <template v-slot:[`item.updatetime`]="{ item }">
        {{ getDateFormat(item.updatetime) }}
      </template>
    </v-data-table>

    <custom-dialog v-model="selectedOpen">
      <template v-slot:title>アクセス権限情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">ロール名</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.role_name"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">ロール名</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >feature
              <p class="mb-0">feature</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-comment-check-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                item-text="name"
                item-value="feature"
                :items="getFeature()"
                v-model="selectedEvent.feature"
                :readonly="!isStartEdit"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">feature</div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">アクセス権限</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">アクセス権限</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">・自分</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">・自分</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center pb-3 pt-n10" no-gutters>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.meView"
                readonly
                label="閲覧"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.meEdit"
                readonly
                label="編集"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.meDelete"
                readonly
                label="削除"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">・他ユーザー</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">・他ユーザー</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center pb-3 pt-n10" no-gutters>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.otherView"
                readonly
                label="閲覧"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.otherEdit"
                readonly
                label="編集"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.otherDelete"
                readonly
                label="削除"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">デフォルト設定</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">デフォルト設定</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center pb-3 pt-n10" no-gutters>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.is_default"
                readonly
                label="新規ユーザー追加時にデフォルト設定する"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">備考</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <text-area-view
                :icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :title="getDispStyle(config.dispStyle).useTitleLabel ? '備考' : ''"
                :text="selectedEvent.remarks"
                :minHeight="'50px'"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card class="mx-1 mt-3" color="background_sub">
          <v-container class="px-6 text-body-2">
            <v-row class="pa-0">
              <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">作成者</v-col>

              <v-col class="pa-0">{{ getUserName(selectedEvent.insertuser) }}</v-col>
              <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.inserttime) }}</v-col>
            </v-row>
            <v-row><v-divider /></v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">更新者</v-col>

              <v-col class="pa-0">{{ getUserName(selectedEvent.updateuser) }}</v-col>
              <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.updatetime) }}</v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="startEditing"> 編集 </v-btn>
        <v-btn color="warning" @click="deleteData"> 削除 </v-btn>
        <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
      </template>
    </custom-dialog>

    <custom-dialog v-model="editedOpen">
      <template v-slot:title>アクセス権限情報</template>
      <template v-slot:body>
        <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.role_name"
          >『 ロール名 』を入力してください。
        </v-alert>

        <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.feature"
          >『 feature 』を入力してください。
        </v-alert>

        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0 required">ロール名</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.role_name"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">ロール名</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >feature
              <p class="mb-0" :class="isStartEdit ? 'required' : ''">feature</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-comment-check-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                item-text="name"
                item-value="feature"
                :items="getFeature()"
                v-model="editedEvent.feature"
                :readonly="!isStartEdit"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel" :class="isStartEdit ? 'required' : ''">
                    feature
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">アクセス権限</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">アクセス権限</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">・自分</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">・自分</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center pb-3 pt-n10" no-gutters>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.meView"
                label="閲覧"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.meEdit"
                label="編集"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.meDelete"
                label="削除"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">・他ユーザー</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">・他ユーザー</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center pb-3 pt-n10" no-gutters>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.otherView"
                label="閲覧"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.otherEdit"
                label="編集"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.otherDelete"
                label="削除"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 pb-0" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">デフォルト設定</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <p class="mb-0">デフォルト設定</p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center pb-3 pt-n10" no-gutters>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.is_default"
                label="新規ユーザー追加時にデフォルト設定する"
                hide-details="auto"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">備考</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-textarea
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.remarks"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">備考</div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">ユーザー</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-row>
                <v-col cols="12">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-account-circle' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="usersList"
                    v-model="editedEvent.user_data"
                    multiple
                    :readonly="true"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">ユーザー</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col cols="12">
              <multi-user-select-list
                :data="editedEvent.user_data"
                :label="'ユーザー選択'"
                :dispNumber="false"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :ismobile="ismobile"
                :useIcon="getDispStyle(config.dispStyle).useIcon"
                :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="endEdit">登録</v-btn>
        <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import fileOperation from '../../../utils/fileOperation';
  import token from '../../../utils/token';
  import dateformat from '../../functions/DateFormat';
  import inputStyleUtils from '../../../utils/inputStyleUtils';
  import CustomDialog from '../../components/CustomDialog.vue';
  import TextAreaView from '../../components/TextAreaView.vue';
  import MultiUserSelectList from '../../components/MultiUserSelectList.vue';

  export default {
    components: { CustomDialog, TextAreaView, MultiUserSelectList },
    data: () => ({
      name: 'roleSettings',
      path: '/roleSettings',
      config: {},
      dense: true,
      ismobile: false,
      search: '',
      listitem: [],
      isError: {
        role_name: false,
        feature: false
      },
      selectedOpen: false,
      selectedEvent: {},
      editedOpen: false,
      editedEvent: {},
      isStartEdit: false,

      windowSize: {
        x: 0,
        y: 0
      },
      cardSize: {
        x: 0,
        y: 0
      },
      disp_list: []
    }),
    created: function () {
      if (!this.initLoading) {
        this.init();
      }
    },
    watch: {
      initLoading(val, old) {
        console.log('watch', val, old);
        if (!val) {
          this.init();
        }
      }
    },
    computed: {
      ...mapState({
        initLoading: state => state.initLoading,
        usersList: state => state.user.usersList,
        usergroup: state => state.userGroup.usergroup,
        userConfig: state => state.userConfig.userconfig,
        role: state => state.role.role,
        roleMap: state => state.roleMap.roleMap,
        feature: state => state.feature.feature
      }),
      outsideLine: {
        get() {
          return `${this.outsideLineElms[0]}-${this.outsideLineElms[1]}-${this.outsideLineElms[2]}`;
        }
      },
      cellphoneNumber: {
        get() {
          return `${this.cellphoneNumberElms[0]}-${this.cellphoneNumberElms[1]}-${this.cellphoneNumberElms[2]}`;
        }
      }
    },
    mixins: [fileOperation, token, inputStyleUtils],
    methods: {
      ...mapActions([
        'fetchUserList',
        'fetchUserGroup',
        'fetchRole',
        'fetchRoleMap',
        'insertRole',
        'updateRole',
        'deleteRole',
        'fetchUserConfig',
        'insertUserConfig',
        'updateUserConfig',
        'insertRoleMap',
        'updateRoleMap',
        'deleteRoleMap'
      ]),
      init() {
        this.dispData();
      },
      getHeaders() {
        const disp_id = {
          text: 'ID',
          value: 'id'
        };
        const role_name = {
          text: 'ロール名',
          value: 'role_name'
        };
        const is_default = {
          text: 'デフォルト設定',
          value: 'is_default'
        };
        const me_action = {
          text: '権限(自分)',
          value: 'me_action'
        };
        const other_action = {
          text: '権限(他ユーザー)',
          value: 'other_action'
        };
        const updateuser = {
          text: '更新者',
          value: 'updateuser'
        };
        const updatetime = {
          text: '更新日時',
          value: 'updatetime'
        };
        return [disp_id, role_name, is_default, me_action, other_action, updateuser, updatetime];
      },
      isMainPage() {
        return this.$route.path === '/' + this.path;
      },
      dispData() {
        const p = [];
        p.push(this.fetchUserList());
        p.push(this.fetchUserGroup());
        p.push(this.fetchRole());
        p.push(this.fetchRoleMap());

        Promise.all(p).then(() => {
          this.listitem = this.role.map(x => this.convToDispItem(x, this.roleMap));
        });
      },
      convToDispItem(x, roleMap) {
        const item = JSON.parse(JSON.stringify(x)); //オブジェクトのコピー

        item.userId = roleMap.filter(x => x.role_id == item.id).map(x => x.user_id);

        this.setActionDataCheckMe(item);
        this.setActionDataCheckOther(item);

        return item;
      },
      showEvent(eventData) {
        const data = eventData;

        this.setActionDataCheckMe(data);
        this.setActionDataCheckOther(data);

        data.user_data = data.userId;

        this.isStartEdit = false;

        this.selectedEvent = data;
        this.selectedOpen = true;
        this.editedOpen = false;
        this.editedEvent = {};
      },
      startEdit() {
        console.log('🐈');

        // featureの設定を追加画面でのみ行うための制御
        this.isStartEdit = true;

        const data = {
          id: -1,
          role_name: '',
          remarks: '',
          is_default: false,
          feature: '',
          user_id: [],
          user_data: [],
          me_action: 0,
          other_action: 0,
          insertuser: this.getUserId(),
          inserttime: new Date(),
          updateuser: this.getUserId(),
          updatetime: new Date(),
          meDelete: false,
          meEdit: false,
          meView: false,
          otherDelete: false,
          otherEdit: false,
          otherView: false
        };

        this.errrorReset();

        this.selectedOpen = false;
        this.selectedEvent = {};
        this.editedOpen = true;
        this.editedEvent = data;
      },
      startEditing() {
        // featureの設定を追加画面でのみ行うための制御
        this.isStartEdit = false;

        this.errrorReset();
        const data = JSON.parse(JSON.stringify(this.selectedEvent));
        data.user_data = data.userId;

        this.selectedOpen = false;
        this.selectedEvent = {};
        this.editedEvent = data;
        this.editedOpen = true;
      },
      errrorReset() {
        for (var key in this.isError) {
          this.isError[key] = false;
        }
      },
      inputCheck() {
        // ロール名チェック
        this.isError.role_name = this.editedEvent.role_name == '';
        // featureチェック
        this.isError.feature = this.editedEvent.feature <= 0;

        for (var key in this.isError) {
          if (this.isError[key]) {
            return false;
          }
        }
        return true;
      },
      async endEdit() {
        //入力チェック
        if (!this.inputCheck()) return;

        // アクセス権限(自分)
        const meActions = {
          meDelete: this.editedEvent.meDelete,
          meEdit: this.editedEvent.meEdit,
          meView: this.editedEvent.meView
        };
        const meAction = this.setActionDataMe(meActions);

        // アクセス権限(他ユーザー)
        const otherActions = {
          otherDelete: this.editedEvent.otherDelete,
          otherEdit: this.editedEvent.otherEdit,
          otherView: this.editedEvent.otherView
        };
        const otherAction = this.setActionDataOther(otherActions);

        try {
          const data = {
            role_name: this.editedEvent.role_name,
            remarks: this.editedEvent.remarks,
            is_default: this.editedEvent.is_default,
            feature: this.editedEvent.feature,
            user_id: this.editedEvent.user_data,
            me_action: meAction,
            other_action: otherAction,
            insertuser: this.getUserId(),
            inserttime: new Date(),
            updateuser: this.getUserId(),
            updatetime: new Date()
          };
          //更新処理の場合
          if (this.editedEvent.id != -1) {
            data.id = this.editedEvent.id;
            data.insertuser = this.editedEvent.insertuser;
            data.inserttime = this.editedEvent.inserttime;

            // roleテーブルとroleMapテーブルの更新
            await this.updateRole(data);
          } else {
            // roleテーブルとroleMapテーブルへ登録
            await this.insertRole(data);
          }
          this.dispData();
          this.cancelEdit();
        } catch (err) {
          console.log(err);
        }
      },
      cancelEdit() {
        this.selectedOpen = false;
        this.editedOpen = false;
      },
      async deleteData() {
        if (!window.confirm(`このロールを削除してよろしいですか？`)) return;
        try {
          const data = this.selectedEvent;
          // roleテーブルとroleMapテーブルの対象データを削除
          await this.deleteRole(data);
          this.dispData();
          this.cancelEdit();
        } catch (err) {
          console.log(err);
        }
      },
      getUserName(id) {
        if (!id) return '---';
        let item = this.usersList.find(item => item.id === id);
        if (!item) return '---';
        return item.disp_name;
      },
      getDateFormat(date) {
        if (!date) return '';

        return dateformat.autoFormat(date);
      },
      fullDateFormat(date) {
        return dateformat.fullFormat(date);
      },

      // 引数で渡されたDBの値を元に、表示用変数に権限情報を設定
      // 自分
      setActionDataCheckMe(eventData) {
        // 10進数→2進数(例:7→111)
        const value = eventData.me_action.toString(2);
        // 3桁の書式にするため0埋め
        // const valuePadding = '0'.repeat(3 - value.length) + value;
        const valuePadding = value.padStart(3, '0');
        // 表示用変数に権限情報を設定
        eventData.meDelete = valuePadding[0] === '1';
        eventData.meEdit = valuePadding[1] === '1';
        eventData.meView = valuePadding[2] === '1';
      },
      // 他ユーザー
      setActionDataCheckOther(eventData) {
        // 10進数→2進数(例:7→111)
        const value = eventData.other_action.toString(2);
        // 3桁の書式にするため0埋め
        const valuePadding = '0'.repeat(3 - value.length) + value;
        // 表示用変数に権限情報を設定
        eventData.otherDelete = valuePadding[0] === '1';
        eventData.otherEdit = valuePadding[1] === '1';
        eventData.otherView = valuePadding[2] === '1';
      },

      // 表示用変数の値を元に、DBに登録する権限情報を設定
      // 自分
      setActionDataMe(meActions) {
        let value = 0;
        if (meActions != undefined) {
          let meAction = [];
          Object.values(meActions).forEach(i => {
            meAction.push(i ? 1 : 0);
          });
          // 2進数→10進数(例:111→7)
          value = parseInt(meAction.join(''), 2);
        }
        return value;
      },
      // 他ユーザー
      setActionDataOther(otherActions) {
        let value = 0;
        if (otherActions != undefined) {
          let otherAction = [];
          Object.values(otherActions).forEach(i => {
            otherAction.push(i ? 1 : 0);
          });
          // 2進数→10進数(例:111→7)
          value = parseInt(otherAction.join(''), 2);
        }
        return value;
      },
      getFeature() {
        const ret = [];
        ret.push(...this.feature);
        return ret;
      },
      dispActionData(meAction) {
        let actionStr = '';

        // 10進数→2進数(例:7→111)
        const value = meAction.toString(2);
        // 3桁の書式にするため0埋め
        const valuePadding = value.padStart(3, '0');

        // actionStr = valuePadding[0] === '1' ? '削除 ' : '';
        // actionStr += valuePadding[1] === '1' ? '編集 ' : '';
        // actionStr += valuePadding[2] === '1' ? '閲覧' : '';

        actionStr = valuePadding[2] === '1' ? '閲覧 ' : '';
        actionStr += valuePadding[1] === '1' ? '編集 ' : '';
        actionStr += valuePadding[0] === '1' ? '削除' : '';

        return actionStr;
      }
      // dispActionData(actionValue) {
      //   // 10進数→2進数(例:7→111)
      //   const value = actionValue.toString(2);
      //   // 3桁の書式にするため0埋め
      //   const valuePadding = value.padStart(3, '0');

      //   const actions = ['削除', '編集', '閲覧'];

      //   // 2進数数値から権限情報を判定(2進数と配列を対応させて判定)
      //   // actionにはactions配列の要素が順に入る
      //   return actions
      //     .map((action, index) => (valuePadding[index] === '1' ? action : ''))
      //     .join(' ');
      // }
    }
  };
</script>
<style scoped>
  .required::after {
    content: '必須';
    color: red;
    font-size: 12px;
    font-weight: bold;
    min-width: 10px;
    padding: 0px 0px;
    margin: 0px 5px;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
  }
</style>
