<template>
  <v-card class="mr-5">
    <v-card-title>
      レイアウト設定

      <v-spacer />
    </v-card-title>
    <!-- レイアウトリスト表示テーブル -->
    <v-data-table
      :headers="getHeaders()"
      :items="listitem"
      item-key="id"
      class="ma-4"
      :mobile-breakpoint="0"
      @click:row="showEvent"
    >
      <template v-slot:[`item.updatetime`]="{ item }">
        {{ getDateFormat(item.updatetime) }}
      </template>
    </v-data-table>
    <custom-dialog v-model="selectedOpen">
      <template v-slot:title>レイアウト情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.page_name"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    レイアウト名称
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
                >mdi-image</v-icon
              >
              <div>
                <p class="mb-1" style="font-size: 0.9em">レイアウトパターン</p>
                <img
                  :src="
                    require(`../../../public/images/icon/layout-${selectedEvent.split_type}.svg`)
                  "
                  width="100px"
                  height="auto"
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
                >mdi-format-list-numbered</v-icon
              >
              <div style="width: 100%">
                <p class="mb-1" style="font-size: 0.9em">表示項目</p>
                <v-card>
                  <v-list
                    style="min-height: max-content"
                    class="overflow-y-auto ma-3"
                    dense
                    v-for="n in layout[selectedEvent.split_type - 1].panels"
                    :key="n"
                  >
                    <p
                      class="pb-1"
                      style="
                        font-size: 0.9em;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                      "
                    >
                      パネル{{ n }}
                    </p>
                    <v-list-item
                      v-for="(item, index) in selectedEvent.user_pages_items
                        .filter(x => x.panel_id === n)
                        .sort((a, b) => a.row - b.row)"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.row }}.{{ getComponentName(item.feature) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-card class="mx-1 mt-3" color="background_sub">
          <v-container class="px-6 text-body-2">
            <v-row class="pa-0">
              <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
            </v-row>
            <v-row><v-divider /></v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">日時</v-col>
              <v-col class="pa-0 text-right">
                {{ fullDateFormat(selectedEvent.updatetime) }}
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="startEditing"> 編集 </v-btn>
        <v-btn
          v-if="selectedEvent.user_id !== 0"
          color="warning"
          @click="deleteData"
        >
          削除
        </v-btn>
        <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog v-model="editedOpen">
      <template v-slot:title>レイアウト設定</template>
      <template v-slot:body>
        <v-alert
          class="pa-0 px-4 py-2 ma-0"
          type="error"
          v-if="isError.duplicate"
          dense
        >
          {{ isError.duplicateName.join('と') }}が複数選択されています。
        </v-alert>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.page_name"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    レイアウト名称
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
                >mdi-image</v-icon
              >
              <div>
                <p class="mb-1" style="font-size: 0.9em">レイアウトの選択</p>
                <v-btn-toggle
                  v-model="editedEvent.split_type"
                  @change="onChange"
                  mandatory
                >
                  <v-btn
                    style="height: max-content; padding: 1.5em"
                    v-for="n in layout"
                    :key="n.id"
                    ><img
                      :src="
                        require(`../../../public/images/icon/layout-${n.split_type}.svg`)
                      "
                      width="100px"
                      height="auto"
                  /></v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
          </v-row>
          <v-row
            class="align-center py-1"
            no-gutters
            v-for="n in layout[editedEvent.split_type].panels"
            :key="n"
          >
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
                >mdi-format-list-numbered</v-icon
              >
              <div style="width: 100%">
                <p class="mb-1" style="font-size: 0.9em">
                  パネル{{ n }} : 表示項目の選択
                </p>
                <v-card
                  class="d-flex background_sub"
                  no-gutters
                  style="width: 100%"
                >
                  <v-col :cols="6">
                    <v-list
                      style="min-height: 200px; max-height: 200px"
                      class="overflow-y-auto"
                      dense
                    >
                      <v-list-item
                        v-for="(item, index) in editedEvent.user_pages_items
                          .filter(x => x.panel_id === n)
                          .sort((a, b) => a.row - b.row)"
                        :key="index"
                        @click="selectComponent(item, n)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ index + 1 }}.{{ getComponentName(item.feature) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col :cols="6">
                    <v-list
                      style="min-height: 200px; max-height: 200px"
                      class="overflow-y-auto"
                      dense
                    >
                      <v-list-item
                        v-for="(item, index) in Object.values(baseComponents)"
                        :key="index"
                        @click="selectComponent(item, n, true)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="register">登録</v-btn>
        <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import fileOperation from '../../../utils/fileOperation';
  import token from '../../../utils/token';
  import CustomDialog from '../../components/CustomDialog.vue';
  import inputStyleUtils from '../../../utils/inputStyleUtils';
  import dateformat from '../../functions/DateFormat';
  import scroll from '../../../utils/scroll';

  export default {
    components: {
      CustomDialog
    },
    data: () => ({
      name: 'pageSetting',
      path: '/pageSetting',
      config: {},
      dense: true,
      ismobile: false,
      headers: [],
      dispmode: 0,
      listitem: [],
      isError: {
        duplicate: false,
        duplicateName: []
      },
      selectedOpen: false,
      selectedFullScreen: false,
      selectedEvent: {},
      editedOpen: false,
      editedFullScreen: false,
      editedEvent: {},

      windowSize: {
        x: 0,
        y: 0
      },
      cardSize: {
        x: 0,
        y: 0
      },
      disp_list: [],
      val: 0,
      // レイアウトパターンに応じて設定できるパネル配列を定義する。split_typeはDBのsplit_typeと同じ
      layout: [
        { panels: [1, 2, 3], split_type: '1' },
        { panels: [1, 2], split_type: '2' },
        { panels: [1, 2, 3], split_type: '3' }
      ],
      // TODO: keyはDB登録名と関連しているので変更する際は注意
      baseComponents: {
        Information: 'お知らせ',
        TimeCard: 'タイムカード',
        Library: 'ライブラリ',
        Workflow: 'ワークフロー',
        Schedule: 'スケジュール'
      }
    }),
    created: function () {
      if (!this.initLoading) {
        this.init();
      }
    },
    watch: {
      initLoading(val, old) {
        console.log('watch', val, old);
        if (!val) {
          this.init();
        }
      }
    },
    computed: {
      ...mapState({
        initLoading: state => state.initLoading,
        usersList: state => state.user.usersList,
        pageSetting: state => state.pagesetting.pagesetting,
        userConfig: state => state.userConfig.userconfig,
        feature: state => state.feature.feature
      }),
      outsideLine: {
        get() {
          return `${this.outsideLineElms[0]}-${this.outsideLineElms[1]}-${this.outsideLineElms[2]}`;
        }
      },
      cellphoneNumber: {
        get() {
          return `${this.cellphoneNumberElms[0]}-${this.cellphoneNumberElms[1]}-${this.cellphoneNumberElms[2]}`;
        }
      }
    },
    mixins: [fileOperation, token, inputStyleUtils, scroll],
    methods: {
      ...mapActions([
        'fetchPageSetting',
        'updatePageSetting',
        'insertPageSetting',
        'deletePageSetting',
        'fetchUserConfig'
      ]),
      init() {
        // DBの機能一覧から有効な物を取得する
        this.baseComponents = this.feature
          .filter(x => x.enable && x.is_useLayout)
          .reduce((acc, current) => {
            acc[current.feature] = current.name;
            return acc;
          }, {});

        this.dispData();
      },
      getHeaders() {
        const page_name = {
          text: '名前',
          value: 'page_name'
        };
        const updatetime = {
          text: '更新日時',
          value: 'updatetime',
          align: 'end',
          width: '150px'
        };
        return [page_name, updatetime];
      },
      isMainPage() {
        return this.$route.path === '/' + this.path;
      },
      dispData() {
        const p = [];
        p.push(this.fetchPageSetting(this.getUserId()));

        Promise.all(p).then(() => {
          this.listitem = this.pageSetting;
        });
      },
      showEvent(eventData) {
        const data = eventData;

        this.selectedOpen = true;
        this.selectedEvent = JSON.parse(JSON.stringify(data));
        // 非活性の機能は表示させない
        this.selectedEvent.user_pages_items =
          this.selectedEvent.user_pages_items.filter(
            x => this.baseComponents[x.feature]
          );
        this.editedOpen = false;
        this.editedEvent = {};
      },
      startEditing() {
        // 編集
        const data = JSON.parse(JSON.stringify(this.selectedEvent));
        this.selectedOpen = false;
        // 編集内容を上書きしてしまっているので削除
        // this.selectedEvent = data;
        this.editedOpen = true;
        this.editedEvent = data;
        // レイアウトパターンで持っている値を変更(配列番号0が、split_type = 1 に相当)
        this.editedEvent.split_type = this.editedEvent.split_type - 1;
        // エラーメッセージ・フラグを初期化
        this.errReset();
      },
      onChange() {
        // パネル数が2つのレイアウトを選択した場合、パネル3に設定しているコンポーネントを削除する
        // 編集時は split_type は配列番号と一致させている
        if (this.editedEvent.split_type === 1) {
          this.editedEvent.user_pages_items =
            this.editedEvent.user_pages_items.filter(x => x.panel_id !== 3);
        }
      },
      selectComponent(target, panelId, isAdd = null) {
        // 設定したコンポーネントを削除する側(追加するときはこの処理は素通り(対象物がないため))
        this.editedEvent.user_pages_items =
          this.editedEvent.user_pages_items.filter(x => target !== x);

        const tmp = this.editedEvent.user_pages_items.filter(
          x => x.panel_id === panelId
        );
        // 表示する行番号を上書き(最初・最後ではないコンポーネントを削除した場合、行番号が狂うため)
        tmp.forEach((ele, i) => {
          ele.row = i + 1;
        });

        // コンポーネントを追加する側
        const num = tmp.length;
        // 1パネルにつき追加できるのは(表示項目数)回まで
        if (isAdd && num < Object.keys(this.baseComponents).length) {
          this.editedEvent.user_pages_items.push({
            panel_id: panelId,
            row: num + 1,
            feature: Object.keys(this.baseComponents).find(
              key => this.baseComponents[key] === target
            )
          });
        }
      },
      inputCheck() {
        // エラー初期化
        this.errReset();
        let flag = true;
        // 同じコンポーネントが選択されていたら、登録更新不可
        const features = this.editedEvent.user_pages_items.map(x => {
          return x.feature;
        });
        // 重複しているコンポーネントのみ抽出
        features
          .filter((y, i, self) => self.indexOf(y) !== i)
          .forEach(ele => {
            console.log(`duplicated Component ${ele}`);
            this.isError.duplicate = true;
            this.isError.duplicateName.push(this.baseComponents[ele]);
            flag = false;
          });
        // 重複を削除
        this.isError.duplicateName = this.isError.duplicateName.filter(
          (y, i, self) => self.indexOf(y) === i
        );
        return flag;
      },
      async register() {
        // 登録
        //入力チェック
        if (!this.inputCheck()) {
          this.doScrollTop();
          return;
        }
        // 削除したコンポーネントがある場合、レコード削除
        // 編集前後を比較して、編集前にしかないfeature配列を作成、取得
        const deleteComponents = this.selectedEvent.user_pages_items
          .map(x => {
            // 削除したコンポーネントがある場合、featureを返す
            if (
              !this.editedEvent.user_pages_items.some(
                y => x.feature === y.feature
              )
            ) {
              return x.feature;
            }
          })
          .filter(x => x !== undefined);

        try {
          const now = new Date();
          // 更新・追加共通
          const data = {
            id: this.selectedEvent.id,
            user_id: this.getUserId(),
            // もとにもどす(配列番号と一致するよう値を変更していたため)
            split_type: this.editedEvent.split_type + 1,
            updateuser: this.getUserId(),
            updatetime: now,
            user_pages_items: this.editedEvent.user_pages_items
          };
          // 削除したコンポーネントがあれば追加
          if (deleteComponents.length > 0) {
            data.delete_components = deleteComponents;
          }
          // レイアウトパネル数を減らした場合、レコード削除
          // 登録するレイアウトパターンが2であるかつ編集前後のsplit_typeが異なる
          // レイアウトパターンで持っていた値にもどすため、1足す
          if (
            this.editedEvent.split_type + 1 === 2 &&
            this.selectedEvent.split_type !== this.editedEvent.split_type + 1
          ) {
            console.log('panel count changed!!!!!!!!!!!!!');
            data.delete_panel = this.selectedEvent.user_pages_items.filter(
              x => x.panel_id === 3
            );
          }

          if (this.editedEvent.user_id) {
            await this.updatePageSetting(data);
          } else {
            // 既定レイアウト(user_id = 0)の場合追加
            // 追加用データ設定
            data.user_id = this.getUserId();
            data.page_num = this.selectedEvent.page_num;
            // レイアウト名はシステム定義
            data.page_name = this.editedEvent.page_name.replace(
              '既定',
              '編集済み'
            );
            data.insertuser = this.getUserId();
            data.inserttime = now;
            await this.insertPageSetting(data);
          }
          this.dispData();
          this.cancelEdit();
        } catch (err) {
          console.log(err);
        } finally {
          this.selectedEvent = {};
        }
      },
      cancelEdit() {
        this.selectedOpen = false;
        this.editedOpen = false;
      },
      async deleteData() {
        //削除確認
        if (!window.confirm(`このレイアウトを削除してよろしいですか？`)) return;
        try {
          await this.deletePageSetting(this.selectedEvent.id);
          this.dispData();
          this.cancelEdit();
        } catch (err) {
          console.log(err);
        }
      },
      getComponentName(feature) {
        // user_pages_items.featureから日本語に変換
        return this.baseComponents[feature];
      },
      getDateFormat(date) {
        return dateformat.autoFormat(date);
      },
      fullDateFormat(date) {
        return dateformat.fullFormat(date);
      },
      errReset() {
        for (var key in this.isError) {
          // 配列ならば初期化・論理型はfalse
          // NOTE: isError に設定するデータ型は配列か論理
          this.isError[key] = Array.isArray(this.isError[key]) ? [] : false;
        }
      }
    }
  };
</script>

<style lang="css">
  .rowBackGround {
    color: darkgray;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
