import http from '../../utils/httpClient';

const state = {
  roleMap: []
};

const mutations = {
  setRoleMap(state, roleMap) {
    state.roleMap = roleMap;
  }
};

const actions = {
  //#region ロールマップ
  async fetchRoleMap(context) {
    await http.get('roleMap').then(res => {
      context.commit('setRoleMap', res.data);
    });
  },
  async updateRoleMap(context, data) {
    return await http.put(`roleMap/${data.id}`, data);
  },
  async insertRoleMap(context, data) {
    return await http.post('roleMap', data);
  },
  async deleteRoleMap(context, data) {
    await http.delete(`roleMap/${data.id}`);
  }
  //#endregion
};

export default {
  state,
  mutations,
  actions
};
