import http from '../../utils/httpClient';

const state = {
  role: []
};

const mutations = {
  setRole(state, role) {
    state.role = role;
  }
};

const actions = {
  //#region ロール
  async fetchRole(context) {
    await http.get('role').then(res => {
      context.commit('setRole', res.data);
    });
  },
  async updateRole(context, data) {
    return await http.put(`role/${data.id}`, data);
  },
  async insertRole(context, data) {
    return await http.post('role', data);
  },
  async deleteRole(context, data) {
    await http.delete(`role/${data.id}`);
  }
  //#endregion
};

export default {
  state,
  mutations,
  actions
};
