<template>
  <v-sheet class="frame" v-resize="onResize" id="info">
    <v-toolbar class="frame" flat>
      <v-btn :to="path" tile text class="pa-0">
        <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon">mdi-bell</v-icon>
        <div class="text-decoration-underline text-body-1" style="width: 7em">お知らせ</div>
      </v-btn>
      <div class="align-end mt-5">
        <v-col cols="12" :md="disp_cols" v-show="!isMainPage() && !ismobile">
          <v-select
            prepend-icon="mdi-bookmark"
            label="カテゴリ"
            item-text="disp_name"
            item-value="id"
            :items="getDispDirectories()"
            v-model="config[`${name}_directry_id`]"
            style="max-width: 250px"
            v-show="disp_cols === 12"
            @change="dispDirectiesChange"
            height="35px"
          >
            <template v-slot:selection="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
            <template v-slot:item="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
          </v-select>
          <v-list v-show="disp_cols !== 12" dense>
            <v-list-item-group v-model="config[`${name}_directry_id`]" mandatory color="indigo">
              <v-list-item
                v-for="(item, index) in this.getDispDirectories()"
                :key="index"
                :value="item.id"
                link
                @click="dispDirectiesChange"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ getFormattedPullDownDispName(item.disp_name) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="info" v-if="canAddEvent()" @click="startEdit" :small="!isMainPage() && !ismobile">追加</v-btn>
    </v-toolbar>
    <v-row class="fill-height" ref="card">
      <v-col cols="12" :md="disp_cols" v-show="isMainPage() || ismobile">
        <v-select
          prepend-icon="mdi-bookmark"
          label="カテゴリ"
          item-text="disp_name"
          item-value="id"
          :items="getDispDirectories()"
          v-model="config[`${name}_directry_id`]"
          style="max-width: 300px"
          v-show="disp_cols === 12"
          class="pl-4"
          @change="dispDirectiesChange"
        >
          <template v-slot:selection="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
          <template v-slot:item="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
        </v-select>
        <v-list v-show="disp_cols !== 12" dense>
          <v-list-item-group v-model="config[`${name}_directry_id`]" mandatory color="indigo">
            <v-list-item
              v-for="(item, index) in this.getDispDirectories()"
              :key="index"
              :value="item.id"
              link
              @click="dispDirectiesChange"
            >
              <v-list-item-content>
                <v-list-item-title>{{ getFormattedPullDownDispName(item.disp_name) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" :md="data_cols">
        <v-data-table
          ref="datatable"
          :headers="getHeaders()"
          :items="listitem"
          @click:row="showEvent"
          :footer-props="{
            'items-per-page-options': [3, 5, 10, 20, 50, 100, 250, 500, -1],
            'items-per-page-text': '行/ページ:'
          }"
          :items-per-page.sync="config[getItemsPerPage()]"
          sort-by="updatetime"
          :sort-desc="true"
          :hide-default-footer="!isMainPage()"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.title`]="{ item }">
            <div>
              <v-icon v-if="isNewIconShow(item)" color="primary">mdi-new-box</v-icon>
              {{ item.title }}
            </div>
          </template>
          <template v-slot:[`item.filename`]="{ item }"
            ><div>
              {{ item.filename }}
            </div>
          </template>
          <template v-slot:[`item.directry_id`]="{ item }">
            {{ getDirectryName(item.directry_id) }}
          </template>
          <template v-slot:[`item.updatetime`]="{ item }">
            {{ getDateFormat(item.updatetime) }}
          </template>
          <template v-slot:[`item.updatetime2`]="{ item }">
            {{ getDateFormat(item.updatetime2) }}
          </template>
          <template v-slot:[`item.updateuser`]="{ item }">
            {{ getUserName(item.updateuser) }}
          </template>
          <template v-slot:[`item.filesize`]="{ item }">
            {{ fileSizeFormat(item.filesize) }}
          </template>
        </v-data-table>

        <custom-dialog v-model="selectedOpen">
          <template v-slot:title>お知らせ</template>
          <template v-slot:body>
            <v-container>
              <v-row class="d-flex align-center py-0" no-gutters>
                <v-container d-flex justify-content-end mt-n4 mb-n8 pt-0>
                  <v-spacer />
                  <div class="pa-0 ma-0 overline">
                    ID I{{ ('000' + selectedEvent.directry_id).slice(-3) }}-{{
                      ('00000000' + selectedEvent.id).slice(-6)
                    }}-{{ ('00' + 1).slice(-2) }}
                  </div>
                </v-container>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">カテゴリ</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    class="mt-2"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="getDispDirectories()"
                    v-model="selectedEvent.directry_id"
                    readonly
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">カテゴリ</div>
                    </template>

                    <template v-slot:selection="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
                    <template v-slot:item="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">タイトル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.title"
                    readonly
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">タイトル</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="!selectedEvent.edit_type == editType.markDown">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">内容</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <text-area-view
                    :icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :title="getDispStyle(config.dispStyle).useTitleLabel ? '内容' : ''"
                    :text="selectedEvent.details"
                    :minHeight="'50px'"
                  />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="selectedEvent.edit_type == editType.markDown">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">内容</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <text-area-view-markdown
                    :icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :title="getDispStyle(config.dispStyle).useTitleLabel ? '内容' : ''"
                    :text="selectedEvent.details2"
                    :minHeight="'50px'"
                  />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">ファイル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <div>
                    <div v-if="!selectedEvent.files || selectedEvent.files.length === 0">添付ファイルなし</div>
                    <v-chip v-for="(item, index) in selectedEvent.files" :key="index" @click="downloadCountUp(item)">{{
                      getFileName(item)
                    }}</v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-card class="mx-1 mt-3" color="background_sub">
              <v-container class="px-6 text-body-2">
                <v-row class="pa-0">
                  <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
                </v-row>
                <v-row class="pa-0">
                  <v-col class="pa-0" cols="2">作成者</v-col>

                  <v-col class="pa-0">{{ getUserName(selectedEvent.insertuser) }}</v-col>
                  <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.inserttime2) }}</v-col>
                </v-row>
                <v-row><v-divider /></v-row>
                <v-row class="pa-0">
                  <v-col class="pa-0" cols="2">更新者</v-col>

                  <v-col class="pa-0">{{ getUserName(selectedEvent.updateuser) }}</v-col>
                  <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.updatetime2) }}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <template v-slot:footer>
            <v-btn color="info" v-show="canEditEvent(selectedEvent)" @click="startEditing">編集</v-btn>
            <v-btn color="warning" v-show="canDeleteEvent(selectedEvent)" @click="deleteData">削除</v-btn>
            <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
          </template>
        </custom-dialog>

        <custom-dialog v-model="editedOpen" :persistent="true">
          <template v-slot:title>トピック追加</template>
          <template v-slot:body>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.file"
              >『 ファイル 』を入力してください。
            </v-alert>

            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.directory"
              >『 カテゴリ 』を入力してください。
            </v-alert>

            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.title"
              >『 タイトル 』を入力してください。
            </v-alert>

            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.release_date_edit" dense
              >『 公開日 』を入力してください。
            </v-alert>

            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.release_time_edit" dense
              >『 公開時刻 』を入力してください。
            </v-alert>

            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.details"
              >『 内容 』を入力してください。
            </v-alert>

            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                  >カテゴリ
                  <p class="mb-0 required">カテゴリ</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    class="mt-2"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="getEditDirectories()"
                    v-model="editedEvent.directry_id"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">カテゴリ</div>
                    </template>

                    <template v-slot:selection="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
                    <template v-slot:item="{ item }">{{ getFormattedPullDownDispName(item.disp_name) }}</template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">タイトル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.title"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">タイトル</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="config.useMarkdown">
                <v-col :cols="ismobile ? 6 : 2" v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                  >編集方式
                  <p class="mb-0 required">編集方式</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 6 : 4">
                  <v-select
                    class="mt-2"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-pencil' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="editTypeList"
                    v-model="editedEvent.edit_type"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">編集方式</div>
                    </template>
                  </v-select>
                </v-col>
                <v-col :cols="ismobile ? 6 : 2" v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                  >公開日時設定
                  <p class="mb-0 required">公開日時設定</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 6 : 4">
                  <v-select
                    class="mt-2"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-calendar-check' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="useReleaseDateList"
                    v-model="editedEvent.use_release_date"
                    hide-details="auto"
                    @change="useReleaseDate_change"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">公開日時設定</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="isUseReleaseDate && config.useMarkdown">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">予定時刻</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-calendar' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="editedEvent.release_date_edit"
                        :readonly="true"
                        @keydown.up="ReleaseDate_UpKeyDown"
                        @keydown.down="ReleaseDate_DownKeyDown"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">公開日</div>
                        </template>

                        <template v-slot:append-outer>
                          <date-picker v-model="editedEvent.release_date_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-clock-outline' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="editedEvent.release_time_edit"
                        :readonly="true"
                        @keydown.up="ReleaseTime_UpKeyDown"
                        @keydown.down="ReleaseTime_DownKeyDown"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">公開時刻</div>
                        </template>
                        <template v-slot:append-outer>
                          <time-picker v-model="editedEvent.release_time_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="editedEvent.edit_type == editType.markDown">
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-btn @click="insertBoldSymbol" elevation="2" x-small>
                    <v-icon>mdi-format-bold</v-icon>
                  </v-btn>
                  <v-btn @click="insertItalicSymbol" elevation="2" x-small>
                    <v-icon>mdi-format-italic</v-icon>
                  </v-btn>
                  <v-btn @click="insertStrikethroughSymbol" elevation="2" x-small>
                    <v-icon>mdi-format-strikethrough</v-icon>
                  </v-btn>
                  <v-btn class="ml-1" @click="insertH1Symbol" elevation="2" x-small>
                    <v-icon>mdi-format-header-1</v-icon>
                  </v-btn>
                  <v-btn @click="insertH2Symbol" elevation="2" x-small>
                    <v-icon>mdi-format-header-2</v-icon>
                  </v-btn>
                  <v-btn @click="insertH3Symbol" elevation="2" x-small>
                    <v-icon>mdi-format-header-3</v-icon>
                  </v-btn>
                  <v-btn @click="insertH4Symbol" elevation="2" x-small>
                    <v-icon>mdi-format-header-4</v-icon>
                  </v-btn>
                  <v-btn @click="insertH5Symbol" elevation="2" x-small>
                    <v-icon>mdi-format-header-5</v-icon>
                  </v-btn>
                  <v-btn @click="insertH6Symbol" elevation="2" x-small>
                    <v-icon>mdi-format-header-6</v-icon>
                  </v-btn>
                  <v-btn class="ml-1" @click="insertListNumberedSymbol" elevation="2" x-small>
                    <v-icon>mdi-format-list-numbered</v-icon>
                  </v-btn>
                  <v-btn @click="insertListBulletedSymbol" elevation="2" x-small>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <v-btn class="ml-1" @click="insertQuoteSymbol" elevation="2" x-small>
                    <v-icon>mdi-format-quote-close</v-icon>
                  </v-btn>

                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ml-1" v-bind="attrs" v-on="on" elevation="2" x-small>
                        <v-icon>mdi-image</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in getEditedFiles(editedEvent.files, editFilesReload)"
                        :key="index"
                        @click="insertImageFormat(item)"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">内容</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-textarea
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.details"
                    ref="detail"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">内容</div>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">ファイル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <file-input
                    ref="fileinput"
                    :params="{
                      label: getDispStyle(config.dispStyle).useTitleLabel ? 'ファイル' : ''
                    }"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-paperclip' : ''"
                  />
                  <div class="py-0 px-3">
                    <v-chip
                      v-for="(item, index) in editedEvent.files"
                      :key="index"
                      close
                      close-icon="mdi-close-outline"
                      @click:close="editedEvent.files = editedEvent.files.filter(n => n !== item)"
                      >{{ getFileName(item) }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="editedEvent.edit_type == editType.markDown">
                <v-col class="pb-3">
                  <v-btn class="ml-7 mt-3" @click="showEventPreview(editedEvent), (preview = true)">
                    プレビューを表示する
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                class="d-flex align-center py-1"
                no-gutters
                v-if="preview && editedEvent.edit_type == editType.markDown"
              >
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">内容</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <text-area-view-markdown
                    :icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :title="getDispStyle(config.dispStyle).useTitleLabel ? '内容' : ''"
                    :text="previewDetails"
                    :minHeight="'50px'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:footer>
            <v-btn color="info" @click="endEdit"> 登録 </v-btn>
            <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
          </template>
        </custom-dialog>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FileInput from '../components/FileInput2.vue';
import CustomDialog from '../components/CustomDialog.vue';
import dateformat from '../functions/DateFormat';
import DateUtils from '../functions/DateUtils';
import TextAreaView from '../components/TextAreaView.vue';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';
import directorySort from '../functions/DirectorySort';
import scroll from '../../utils/scroll';
import TextAreaViewMarkdown from '../components/TextAreaViewMarkdown.vue';
import DatePicker from '../components/DatePicker';
import TimePicker from '../components/TimePicker';
import MarkdownFormat from '../functions/MarkdownFormat';

export default {
  components: {
    FileInput,
    TextAreaView,
    CustomDialog,
    TextAreaViewMarkdown,
    DatePicker,
    TimePicker
  },
  data: () => ({
    name: 'information',
    path: '/information',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    listitem: [],
    fileindexlist: [],
    // directories: [],
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    dialogLeft: 0,
    disp_cols: 2,
    data_cols: 10,
    isError: {
      file: false,
      directory: false,
      title: false,
      details: false,
      release_date_edit: false,
      release_time_edit: false
    },
    selectedOpen: false,
    selectedFullScreen: false,
    selectedEvent: {},
    editedOpen: false,
    editedFullScreen: false,
    editedEvent: {},
    uploadCount: 0,
    uploadStartFile: [],
    uploadEndFile: [],
    editTypeList: [
      { id: 0, disp_name: 'テキスト方式' },
      { id: 1, disp_name: 'マークダウン方式' }
    ],
    editType: { text: 0, markDown: 1 },
    editFilesReload: false,
    fileDatas: [],
    preview: true,
    previewDetails: '',
    useReleaseDateList: [
      { id: 0, disp_name: '設定しない', isUse: false },
      { id: 1, disp_name: '設定する', isUse: true }
    ],
    isUseReleaseDate: false,
    copyReleaseDate: '',
    copyReleaseTime: '',
    newScheduleTimeUnit: 30
  }),
  created: function () {
    // ダミーの空データを設定する
    this.listitem = [{}, {}, {}];

    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    reLoading() {
      this.reload();
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      reLoading: state => state.reLoading,
      usersList: state => state.user.usersList,
      filedata: state => state.fileData.filedata,
      userConfig: state => state.userConfig.userconfig,
      informationdata: state => state.information.informationdata,
      informationDirectory: state => state.informationDirectory.informationDirectory,
      systemsettings: state => state.systemsettings
    })
  },
  mixins: [fileOperation, token, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'fetchUserConfig',
      'upsertUserConfig',
      'fetchInformationData',
      'insertInformationData',
      'updateInformationData',
      'updateInformationDataWithFile',
      'deleteInformationData',
      'fetchInformationDirectory',
      'fetchFileData',
      'downloadFile'
    ]),
    init() {
      let p = [];
      p.push(this.fetchInformationDirectory());

      Promise.all(p).then(() => {
        let config = this.userConfig.find(item => item.user_id === this.getUserId()) ?? {};

        this.config[`${this.name}_directry_id`] = config[`${this.name}_directry_id`] || this.getDispDirectories()[0].id;

        this.config[this.getItemsPerPage()] = config[this.getItemsPerPage()] || (this.isMainPage() ? 10 : 3);

        this.config.dispStyle = config.dispStyle || 1;
        // マークダウンを扱うかどうか
        this.config.useMarkdown = this.systemsettings.information_markdown;

        if (!this.getDispDirectories().find(x => x.id === this.config[`${this.name}_directry_id`])) {
          this.config[`${this.name}_directry_id`] = this.getDispDirectories()[0].id;
        }

        this.dispInformationData();
      });
    },
    reload() {
      //データ取得
      this.dispInformationData();
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.ismobile = document.getElementById('info').clientWidth < 800;
      this.selectedFullScreen = this.ismobile;
      this.editedFullScreen = this.ismobile;

      this.headers = this.getHeaders();
      if (this.isMainPage()) {
        this.disp_cols = 2;
        this.data_cols = 10;
      } else {
        this.disp_cols = 12;
        this.data_cols = 12;
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    getItemsPerPage() {
      return `${this.name}_items_per_page_${this.isMainPage() ? 'main' : 'sub'}`;
    },
    getHeaders() {
      const directry_id = {
        text: 'カテゴリ',
        value: 'directry_id',
        width: '150px'
      };
      // const filename = {
      //   text: 'ファイル名',
      //   value: 'filename'
      // };
      const title = {
        text: 'タイトル',
        value: 'title'
      };
      const updateuser = {
        text: '更新者',
        value: 'updateuser',
        width: '120px'
      };
      const updatetime = {
        text: '更新日時',
        value: 'updatetime2',
        align: 'end',
        width: '150px'
      };
      // const dlcount = {
      //   text: 'DL数',
      //   value: 'dlcount',
      //   align: 'end',
      //   width: '100px'
      // };
      // const filesize = {
      //   text: '容量',
      //   value: 'filesize',
      //   align: 'end',
      //   width: '100px'
      // };
      if (this.cardSize.x < 400) {
        return [title, updatetime];
      } else if (this.cardSize.x < 600) {
        return [title, updatetime];
      } else if (this.cardSize.x < 1000) {
        return [directry_id, title, updateuser, updatetime];
      } else {
        return [directry_id, title, updateuser, updatetime];
      }
    },
    getEditedFiles(editedfiles) {
      const ret = editedfiles.map(x => ({
        id: x,
        name: this.getOriginalName(x)
      }));
      if (this.$refs.fileinput) {
        const files = this.$refs.fileinput.files;
        ret.push(...files.map(x => ({ id: -1, name: x.name })));
      }
      return ret;
    },
    canAddEvent() {
      //新規登録可否
      return this.getPermission(this.TOKEN_FEATURE.INFORMATION, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.EDIT);
    },
    canEditEvent(event) {
      let result = false;
      if (!event.insertuser) return false;

      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.INFORMATION,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.EDIT
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.INFORMATION,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.EDIT
      );

      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      //編集権限のないフォルダ
      if (!this.getEditDirectories().find(x => x.id === event.directry_id)) {
        result = false;
      }
      return result;
    },
    canDeleteEvent(event) {
      let result = false;
      if (!event.insertuser) return false;
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.INFORMATION,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.DELETE
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.INFORMATION,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.DELETE
      );
      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      //編集権限のないフォルダ
      if (!this.getEditDirectories().find(x => x.id === event.directry_id)) {
        result = false;
      }

      return result;
    },

    async showEvent(data) {
      this.selectedEvent = {};
      this.selectedEvent = JSON.parse(JSON.stringify(data));
      this.selectedEvent.details2 = this.selectedEvent.details;

      // 前回表示した画像をアンロードする
      this.unloadFileDatas();

      if (data.edit_type == this.editType.markDown) {
        // マークダウン
        // ダイアログを開いたタイミングで配列に値を入れる
        const useFiles = data.files.map(x => this.filedata.find(y => y.id == x));

        // 読み込み
        this.fileDatas = await this.loadFiledata(useFiles);

        // 置き換え
        this.selectedEvent.details2 = this.replaceDetailURL(this.selectedEvent.details, this.fileDatas);
      }
      this.selectedOpen = true;
    },
    // プレビュー用
    async showEventPreview(data) {
      // this.editedEvent = {};
      // this.editedEvent = JSON.parse(JSON.stringify(data));
      // this.previewDetails = this.editedEvent.details;

      // プレビューボタンを押したタイミングで配列に値を入れる
      const useFiles = data.files.map(x => this.filedata.find(y => y.id == x));

      // 前回表示した画像をアンロードする
      this.unloadFileDatas();

      // 読み込み
      this.fileDatas = await this.loadFiledata(useFiles);
      const editFileDatas = await this.loadFiledataPreview(this.$refs.fileinput.files);
      this.fileDatas.push(...editFileDatas);

      // 置き換え
      this.previewDetails = this.replaceDetailURL(this.editedEvent.details, this.fileDatas);
    },
    // 読み込み
    async loadFiledata(useFiles) {
      const fileDatas = [];
      await Promise.all(
        useFiles.map(x => {
          return this.downloadFile(x).then(res => {
            const filedata = {
              url: window.URL.createObjectURL(new Blob([res.data])),
              filename: x.originalname
            };
            fileDatas.push(filedata);
          });
        })
      );
      return fileDatas;
    },
    // 読み込み(プレビュー用)
    async loadFiledataPreview(inputFiles) {
      return inputFiles.map(x => ({
        url: window.URL.createObjectURL(x),
        filename: x.name
      }));
    },
    /**
     * @text 置換対象の文字列(内容)
     * @fileDatas 置換対象を置き換えるためのデータ(添付ファイル)
     */
    replaceDetailURL(text, fileDatas) {
      // 置き換え
      fileDatas.map(x => {
        const serchword = `![` + x.filename + ']()';
        const newword = `![` + x.filename + '](' + x.url + ')';

        text = text.replaceAll(serchword, newword);
      });
      return text;
    },
    unloadFileDatas() {
      this.fileDatas.map(x => {
        window.URL.revokeObjectURL(x.url);
      });
    },
    downloadCountUp(id) {
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '';
      this.downloadFile(item).then(res => {
        // 取得したファイルをダウンロードできるようにする
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', item.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

      const data = this.selectedEvent;
      data.dlcount += 1;
      this.updateInformationData(data).then(() => {
        //表示するスケジュールの一覧を取得
        this.dispInformationData();
      });
    },
    startEdit() {
      // 追加画面はデフォルトで公開日時設定をしない
      this.isUseReleaseDate = false;
      const use_release_date = this.useReleaseDateList.find(item => item.isUse === this.isUseReleaseDate)?.id;

      const data = {
        id: -1,
        title: '',
        filename: '',
        files: [],
        directry_id: this.config[`${this.name}_directry_id`],
        details: '',
        dlcount: 0,
        filesize: 0,
        edit_type: 0,
        release_date_edit: dateformat.format(DateUtils.getToday(), 'YYYY-MM-DD'),
        release_time_edit: dateformat.format(DateUtils.getNow(), 'HH:mm'),
        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date(),
        use_release_date: use_release_date
      };
      if (!this.getEditDirectories().find(x => x.id === data.directry_id)) {
        data.directry_id = -1;
      }

      // 公開日時を保存(登録時、即時公開のダイアログを出すかの条件に使用)
      this.copyReleaseDate = data.release_date_edit;
      this.copyReleaseTime = data.release_time_edit;

      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }

      this.preview = false;
      this.previewDetails = '';

      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedOpen = true;
      this.editedEvent = data;
    },
    startEditing() {
      // 公開日時を保存(登録時、即時公開のダイアログを出すかの条件に使用)
      this.copyReleaseDate = this.selectedEvent.release_date_edit;
      this.copyReleaseTime = this.selectedEvent.release_time_edit;

      // 編集画面は 公開日時 > 現在時刻のときだけ公開日時設定を「表示する」にする
      this.isUseReleaseDate = new Date(this.selectedEvent.release_date) > DateUtils.getNow();
      const use_release_date = this.useReleaseDateList.find(item => item.isUse === this.isUseReleaseDate)?.id;

      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }
      this.preview = false;
      this.previewDetails = '';
      this.editedEvent = JSON.parse(JSON.stringify(this.selectedEvent));
      this.editedEvent.use_release_date = use_release_date;
      this.selectedOpen = false;
      this.editedOpen = true;
    },
    deleteData() {
      //削除確認
      if (!window.confirm(`このトピックを削除してよろしいですか？`)) return;

      let results = [];
      let promise = null;

      const fileIds = this.selectedEvent.files;
      //informationデータを付随するファイルデータとともに削除
      if (promise == null) {
        promise = this.deleteInformationData({
          id: this.selectedEvent.id,
          fileIds
        });
      } else {
        promise = promise.then(result => {
          results.push(result);
          return this.deleteInformationData({
            id: this.selectedEvent.id,
            fileIds
          });
        });
      }
      promise.then(() => {
        //表示するinformationデータの一覧を取得
        this.dispInformationData();
        this.selectedOpen = false;
        this.editedOpen = false;
      });
    },
    errrorReset() {
      for (var key in this.isError) {
        this.isError[key] = false;
      }
    },
    inputCheck() {
      // let files = this.$refs.fileinput.files;
      //ファイル数チェック
      // this.isError.file = files.length <= 0;
      //件名チェック
      this.isError.title = this.editedEvent.title == '';
      this.isError.details = this.editedEvent.details == '';
      // 公開日チェック
      this.isError.release_date_edit = this.editedEvent.release_date_edit == null;
      // 公開時刻チェック
      this.isError.release_time_edit = this.editedEvent.release_time_edit == null;

      for (var key in this.isError) {
        if (this.isError[key]) {
          return false;
        }
      }
      return true;
    },
    endEdit() {
      //入力チェック
      if (!this.inputCheck()) {
        this.doScrollTop();
        return;
      }

      const releaseDateTime = new Date(
        this.editedEvent.release_date_edit + 'T' + this.editedEvent.release_time_edit + '+09:00'
      );
      const now = DateUtils.getNow();
      // 公開日が過去日時
      if (now >= releaseDateTime) {
        // 公開日or公開時刻を変更しているときのみダイアログを表示
        if (
          this.copyReleaseDate != this.editedEvent.release_date_edit ||
          this.copyReleaseTime != this.editedEvent.release_time_edit
        ) {
          const msg = '公開日時が過去で指定されている場合、即時公開されます。よろしいですか？';
          if (!window.confirm(msg)) return;
        }
      }

      let files = this.$refs.fileinput.files;

      let results = [];
      let promise = null;

      let mainFunc = () => {
        let formData = new FormData();
        if (files.length > 0) {
          files.forEach(file => {
            formData.append('files', file);
          });
        }

        const data = {
          title: this.editedEvent.title,
          filename: '',
          files: Array.from(this.editedEvent.files),
          directry_id: this.editedEvent.directry_id,
          details: this.editedEvent.details,
          dlcount: this.editedEvent.dlcount,
          filesize: 0,
          edit_type: this.editedEvent.edit_type,
          release_date: releaseDateTime,
          insertuser: this.editedEvent.insertuser,
          inserttime: this.editedEvent.inserttime,
          updateuser: this.getUserId(),
          updatetime: new Date()
        };

        let promise = null;
        if (this.editedEvent.id != -1) {
          const originalFileIds = this.selectedEvent.files; // 編集前のfileIds
          const editedFileIds = this.editedEvent.files; // 編集後のfileIds
          // 編集の結果、削除すべきファイルのid
          const fileIdsToDelete = originalFileIds.filter(id => editedFileIds.indexOf(id) === -1);
          // 編集の結果、残しておくべきファイルのid
          const noDeletedFileIds = originalFileIds.filter(id => editedFileIds.indexOf(id) !== -1);

          // リクエストのbodyに以下のデータを追加
          data.fileIdsToDelete = fileIdsToDelete;
          data.noDeletedFileIds = noDeletedFileIds;
          data.id = this.editedEvent.id; // informationデータのid
          formData.append('data', JSON.stringify(data));
          // Informationデータの更新および付随するファイルデータのアップロード
          promise = this.updateInformationDataWithFile(formData);
        } else {
          formData.append('data', JSON.stringify(data));
          // Informationデータの新規登録および付随するファイルデータのアップロード
          promise = this.insertInformationData(formData);
        }

        promise
          .then(() => {
            //表示するinformationデータの一覧を取得
            this.dispInformationData();
            this.editedOpen = false;
          })
          .catch(err => {
            console.log(err);
          });
      };

      if (promise == null) {
        mainFunc();
      } else {
        promise
          .then(result => {
            results.push(result);
            mainFunc();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    cancelEdit() {
      this.$refs.fileinput.fileClear();
      this.editedOpen = false;
    },

    dispDirectiesChange() {
      this.dispInformationData();
    },
    dispInformationData() {
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.INFORMATION,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.VIEW
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.INFORMATION,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.VIEW
      );
      //ディレクトリ情報取得
      this.fetchInformationDirectory()
        .then(() => {
          const data = { directry_id: [] };

          //ディレクトリ一覧の更新
          let baseDir = this.getDispDirectories().find(item => item.id == this.config[`${this.name}_directry_id`]);

          if (!baseDir) baseDir = { path: '-' };

          //階層の情報を取得
          data.directry_id = this.getDispDirectories()
            .filter(item => item.path.indexOf(baseDir.path) === 0)
            .map(item => item.id);

          //ファイルインデックス情報、お知らせ情報を取得
          Promise.all([this.fetchFileData(), this.fetchInformationData()]).then(() => {
            this.listitem = this.informationdata
              .filter(
                x =>
                  data.directry_id.indexOf(x.directry_id) >= 0 &&
                  ((x.insertuser === this.getUserId() && mePermission) ||
                    (x.insertuser !== this.getUserId() && otherPerMission))
              )
              .map(x => this.convToDispItem(x)); // 公開日と公開時刻の成形、表示用データの成形

            //検索設定の保存
            this.saveConfig();
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    convToDispItem(x) {
      // const item = {...x};//オブジェクトのコピー
      const item = JSON.parse(JSON.stringify(x)); //オブジェクトのコピー

      if (!item.release_date) {
        item.release_date = item.inserttime;
      }
      const releaseDate = new Date(item.release_date);
      item.release_date_edit = dateformat.format(releaseDate, 'YYYY-MM-DD');
      item.release_time_edit = dateformat.format(releaseDate, 'HH:mm');

      // 作成日(表示用)
      const insertTime = new Date(item.inserttime);
      item.inserttime2 = insertTime < releaseDate ? releaseDate : insertTime;
      // 更新日(表示用)
      const updateTime = new Date(item.updatetime);
      item.updatetime2 = updateTime < releaseDate ? releaseDate : updateTime;

      return item;
    },
    ReleaseDate_UpKeyDown(e) {
      //上キー
      let event = this.editedEvent;
      event.release_date_edit = this.dateDecrement(event.release_date_edit);
      e.preventDefault(); //イベントcancel
    },
    ReleaseDate_DownKeyDown(e) {
      //下キー
      let event = this.editedEvent;
      event.release_date_edit = this.dateIncrement(event.release_date_edit);
      e.preventDefault(); //イベントcancel
    },
    ReleaseTime_UpKeyDown(e) {
      //上キー
      let event = this.editedEvent;
      event.release_time_edit = this.timeDecrement(event.release_time_edit);
      e.preventDefault(); //イベントcancel
    },
    ReleaseTime_DownKeyDown(e) {
      //下キー
      let event = this.editedEvent;
      event.release_time_edit = this.timeIncrement(event.release_time_edit);
      e.preventDefault(); //イベントcancel
    },
    dateDecrement(datestr) {
      return dateformat.format(DateUtils.addDay(datestr, -1), 'YYYY-MM-DD');
    },
    dateIncrement(datestr) {
      return dateformat.format(DateUtils.addDay(datestr, 1), 'YYYY-MM-DD');
    },
    timeDecrement(timestr) {
      let time = this.timeSplit(timestr);
      let allmm = time.hh * 60 + time.mm;
      allmm += this.newScheduleTimeUnit;
      allmm = allmm % (24 * 60);
      time.hh = Math.floor(allmm / 60);
      time.mm = allmm % 60;
      return this.timeJoin(time.hh, time.mm);
    },
    timeIncrement(timestr) {
      let time = this.timeSplit(timestr);
      let allmm = time.hh * 60 + time.mm;
      allmm += 24 * 60;
      allmm -= this.newScheduleTimeUnit;
      allmm = allmm % (24 * 60);
      time.hh = Math.floor(allmm / 60);
      time.mm = allmm % 60;
      return this.timeJoin(time.hh, time.mm);
    },
    timeSplit(time) {
      let hhmm = time.split(':');
      let hh = parseInt(hhmm[0]);
      let mm = parseInt(hhmm[1]);
      return { hh: hh, mm: mm };
    },
    timeJoin(hh, mm) {
      return ('00' + hh).slice(-2) + ':' + ('00' + mm).slice(-2);
    },
    saveConfig() {
      //選択状態を保存する
      let promise = this.fetchUserConfig();
      promise = promise
        .then(() => {
          let config = this.userConfig.find(item => item.user_id === this.getUserId());

          if (!config) {
            config = {};
            config.user_id = this.getUserId();
          }
          config[`${this.name}_directry_id`] = this.config[`${this.name}_directry_id`];
          return this.upsertUserConfig(config);
        })
        .catch(err => {
          console.log(err);
        });
      promise.catch(err => {
        console.log(err);
      });
      return promise;
    },
    getDispDirectories() {
      // 表示ディレクトリ抽出
      const directories = this.informationDirectory.filter(x => this.getParentDisp(x));
      // ソート
      return directorySort.directorysort(directories);
    },
    getEditDirectories() {
      // 編集可ディレクトリ抽出
      const directories = this.informationDirectory.filter(x => this.getParentEdit(x));
      // ソート
      return directorySort.directorysort(directories);
    },
    canDispDirectory(ldItem) {
      switch (ldItem.public_lebel) {
        case 0:
          // 全てのユーザーが閲覧／追加／編集／削除可。
          return true;
        case 1:
          // 全てのユーザーが閲覧可。所属メンバーのみ追加／編集／削除可。
          return true;
        case 2:
          // 所属メンバーのみ閲覧／追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 3:
          // 自分のみ閲覧／追加／編集／削除可。
          return ldItem.insertuser === this.getUserId();
        default:
          //？？？
          return false;
      }
    },
    canEditDirectory(ldItem) {
      switch (ldItem.public_lebel) {
        case 0:
          // 全てのユーザーが閲覧／追加／編集／削除可。
          return true;
        case 1:
          // 全てのユーザーが閲覧可。所属メンバーのみ追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 2:
          // 所属メンバーのみ閲覧／追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 3:
          // 自分のみ閲覧／追加／編集／削除可。
          return ldItem.insertuser === this.getUserId();
        default:
          //？？？
          return false;
      }
    },
    isNewIconShow(item) {
      if (!item.updatetime) return false;
      const itemDate = DateUtils.getDate(this.getUpdateTime(item));
      const targetDate = DateUtils.addDay(DateUtils.getNow(), -7);
      //1週間前のものは新着扱い
      return itemDate > targetDate;
    },
    getDownloadLink(id) {
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '';
      return process.env.VUE_APP_API_URL_FILE + '/download/' + item.filename;
    },
    getFileName(id) {
      if (!id) return '---';
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '---';
      return item.originalname + '(' + this.fileSizeFormat(item.filesize) + ')';
    },
    getOriginalName(id) {
      if (!id) return '---';
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '---';
      return item.originalname;
    },
    getDirectryName(id) {
      if (!id) return '---';
      let item = this.getDispDirectories().find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      if (!date) return '';

      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    },
    getFormattedPullDownDispName(disp_name) {
      const len = this.getCategoryDepthByUnderbar(disp_name);
      if (disp_name === 'ルート' || !len) {
        return disp_name;
      }
      let formatted_disp_name = '';
      // 表示名の先頭を_から全角スペースに置き換える(_のままだと表示した時に階層構造が分かりずらいため)
      for (let i = 0; i < len; i++) {
        formatted_disp_name += '\u{3000}';
      }
      // 元の表示名から先頭の_を消去したものを加える。
      formatted_disp_name += disp_name.slice(len);
      return formatted_disp_name;
    },
    /* フォルダの階層の深さをフォルダ名先頭のアンダーバー_の数で求める。*/
    getCategoryDepthByUnderbar(disp_name) {
      if (!disp_name) {
        return;
      }
      const ex = /^_*/g;
      const arr = disp_name.match(ex);
      if (!arr) {
        return;
      }
      return arr[0].length;
    },
    /* フォルダの階層の深さをパスのスラッシュの数で求める。*/
    getCategoryDepthBySlash(path) {
      if (!path) {
        return;
      }
      const ex = /\//g;
      const arr = path.match(ex);
      if (!arr) {
        return;
      }
      return arr.length;
    },
    // directoryの親ディレクトリを含むアクセス可否を判定する
    getParentDisp(directory) {
      if (this.canDispDirectory(directory)) {
        let DispFlg = true;
        const splitPath = directory.path.split('/');
        let parentDirectory = directory;
        for (let i = 1; i < splitPath.length; i++) {
          // パス名から、親ディレクトリ部分のパスを抽出
          parentDirectory = this.getParentDetail(parentDirectory);

          if (parentDirectory !== undefined) {
            DispFlg = DispFlg && this.canDispDirectory(parentDirectory);
          } else {
            DispFlg = false;
          }
        }
        return DispFlg;
      }
      return false;
    },
    getParentEdit(directory) {
      if (this.canEditDirectory(directory)) {
        let EditFlg = true;
        const splitPath = directory.path.split('/');
        let parentDirectory = directory;
        for (let i = 1; i < splitPath.length; i++) {
          // パス名から、親ディレクトリ部分のパスを抽出
          parentDirectory = this.getParentDetail(parentDirectory);

          if (parentDirectory !== undefined) {
            EditFlg = EditFlg && this.canEditDirectory(parentDirectory);
            if (parentDirectory.public_lebel === 1) {
              EditFlg = true;
            }
          } else {
            EditFlg = false;
          }
        }
        return EditFlg;
      }
      return false;
    },
    // 与えられたディレクトリの親ディレクトリ情報を取得する
    getParentDetail(directory) {
      const splitPath = directory.path.split('/');
      // ルートの直下のディレクトリはそのまま返す
      if (splitPath.length > 2) {
        // パス名から、親ディレクトリ部分のパスを抽出
        const parentPath = splitPath.slice(0, splitPath.length - 1).join('/');
        // 親ディレクトリのプロパティを取得
        let parentDirectory = Object.assign(
          {},
          this.informationDirectory.find(d => d.path === parentPath)
        );
        return parentDirectory;
      } else {
        return directory;
      }
    },

    // 太字
    insertBoldSymbol() {
      const textarea = this.$refs.detail;
      const boldText = '**';

      this.editedEvent.details = MarkdownFormat.textareaPutInSymbol(textarea, boldText);
    },
    // 斜体
    insertItalicSymbol() {
      const textarea = this.$refs.detail;
      const italicText = '*';

      this.editedEvent.details = MarkdownFormat.textareaPutInSymbol(textarea, italicText);
    },
    // 取り消し線
    insertStrikethroughSymbol() {
      const textarea = this.$refs.detail;
      const strikethroughText = '~~';

      this.editedEvent.details = MarkdownFormat.textareaPutInSymbol(textarea, strikethroughText);
    },
    // 見出し1
    insertH1Symbol() {
      const textarea = this.$refs.detail;

      const h1Text = '# ';

      this.editedEvent.details = MarkdownFormat.insertHxSymbol(textarea, h1Text);
    },
    // 見出し2
    insertH2Symbol() {
      const textarea = this.$refs.detail;

      const h2Text = '## ';

      this.editedEvent.details = MarkdownFormat.insertHxSymbol(textarea, h2Text);
    },
    // 見出し3
    insertH3Symbol() {
      const textarea = this.$refs.detail;

      const h3Text = '### ';

      this.editedEvent.details = MarkdownFormat.insertHxSymbol(textarea, h3Text);
    },
    // 見出し4
    insertH4Symbol() {
      const textarea = this.$refs.detail;

      const h4Text = '#### ';

      this.editedEvent.details = MarkdownFormat.insertHxSymbol(textarea, h4Text);
    },
    // 見出し5
    insertH5Symbol() {
      const textarea = this.$refs.detail;

      const h5Text = '##### ';

      this.editedEvent.details = MarkdownFormat.insertHxSymbol(textarea, h5Text);
    },
    // 見出し6
    insertH6Symbol() {
      const textarea = this.$refs.detail;

      const h6Text = '###### ';

      this.editedEvent.details = MarkdownFormat.insertHxSymbol(textarea, h6Text);
    },
    // 番号付きリスト
    insertListNumberedSymbol() {
      // テキストエリアのカーソルの位置を取得
      const textarea = this.$refs.detail;

      const numberedText = '1. ';

      this.editedEvent.details = MarkdownFormat.insertListSymbol(textarea, numberedText);
    },
    // 箇条書きリスト
    insertListBulletedSymbol() {
      // テキストエリアのカーソルの位置を取得
      const textarea = this.$refs.detail;

      const bulletedText = '- ';

      this.editedEvent.details = MarkdownFormat.insertListSymbol(textarea, bulletedText);
    },
    // インライン表示
    insertQuoteSymbol() {
      const textarea = this.$refs.detail;
      const quoteText = '`';

      this.editedEvent.details = MarkdownFormat.textareaPutInSymbol(textarea, quoteText);
    },
    // 画像表示用フォーマット挿入
    insertImageFormat(item) {
      const textarea = this.$refs.detail.$refs.input;
      const imageFormat = `![` + item.name + ']()';

      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      let details = this.editedEvent.details;

      details = details.slice(0, start) + imageFormat + details.slice(end);

      this.editedEvent.details = details;

      // カーソル位置を更新
      setTimeout(() => textarea.setSelectionRange(start, end + imageFormat.length));
      this.$refs.detail.focus();
    },

    // 公開日時設定しない／するを変更したタイミングで、公開日時コントロールの非表示／表示を切り替える変数を更新する
    useReleaseDate_change() {
      const item = this.useReleaseDateList.find(x => x.id === this.editedEvent.use_release_date);
      if (item) {
        this.isUseReleaseDate = item.isUse;
      }
    },

    getUpdateTime(eventData) {
      return eventData.updatetime < eventData.release_date_edit ? eventData.release_date_edit : eventData.updatetime;
    }
  }
};
</script>
<style scoped>
.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
