const markdownformat = {
  newLine: '\n',

  // 文字装飾(太字、斜体、取り消し線、インライン表示)
  textareaPutInSymbol(textarea, insertText) {
    const textareaInput = textarea.$refs.input;
    // テキストエリアのカーソルの位置を取得
    const start = textareaInput.selectionStart;
    const end = textareaInput.selectionEnd;

    let details = textarea.value;

    // 選択範囲の前後に特定の文字を挿入
    details = this.putInSymbol(details, start, end, insertText);

    // カーソル位置を更新
    setTimeout(() =>
      // textareaInput.setSelectionRange(start, end + insertText.length * 2)
      // 挿入した文字以外を選択範囲にする
      textareaInput.setSelectionRange(
        start + insertText.length,
        end + insertText.length
      )
    );
    textarea.focus();

    return details;
  },
  putInSymbol(details, start, end, insertText) {
    // 選択範囲の前後に特定の文字を挿入
    return (
      details.slice(0, start) +
      insertText +
      details.slice(start, end) +
      insertText +
      details.slice(end)
    );
  },

  // 見出し
  insertHxSymbol(textarea, insertText) {
    const textareaInput = textarea.$refs.input;

    // テキストエリアのカーソルの位置を取得
    let start = textareaInput.selectionStart;

    let details = textarea.value;

    // 先頭からカーソル位置まで
    const forwardText = details.slice(0, start);
    // forwardTextの中で最後に出てくる改行文字を検索する
    let lineStartIndex = forwardText.lastIndexOf(this.newLine) + 1;
    // カーソル位置以降の改行文字を検索する
    let lineEndIndex = details.indexOf(this.newLine, start);
    // カーソル位置の行の文字列を取得
    if (lineEndIndex < 0) {
      // カーソル位置以降に改行文字がない
      lineEndIndex = details.length;
    }
    const cursorLine = details.slice(lineStartIndex, lineEndIndex);

    // 2種類以上の見出し、リストと見出しを同時に使用できないようにする
    let newCursorLine = cursorLine;
    const hitListHxSymbol =
      this.findListSymbol(cursorLine) + this.findHxSynbol(cursorLine);
    if (hitListHxSymbol.length > 0) {
      if (hitListHxSymbol != insertText) {
        // 既に付いている場合は、先頭についている文字を取り除く
        newCursorLine = newCursorLine.slice(hitListHxSymbol.length);
      } else {
        // 何もしない
      }
    }

    // 「# 」を付ける対象はカーソルのある行のみ
    // 既に先頭に「# 」が付いているか
    if (newCursorLine.slice(0, insertText.length) != insertText) {
      // まだ付いていない場合は「# 」を付ける
      newCursorLine = insertText + newCursorLine;
    } else {
      // 既に付いている場合は「# 」を取り除く
      newCursorLine = newCursorLine.slice(insertText.length);
    }

    details =
      details.slice(0, lineStartIndex) +
      newCursorLine +
      details.slice(lineEndIndex);

    let selectStartIndex = lineStartIndex;
    let selectEndIndex = selectStartIndex + newCursorLine.length;

    // ボタン押下後の文字列を判定
    if (newCursorLine.slice(0, insertText.length) == insertText) {
      // 「# 」が付いているときは「# 」以降の文字列が選択範囲
      // 選択範囲の始まりが「# 」の後からになるように調整
      selectStartIndex += insertText.length;
    } else {
      // 「# 」が付いていないときは文字列全体が選択範囲※処理なし
    }

    // カーソル位置を更新
    setTimeout(() =>
      textareaInput.setSelectionRange(selectStartIndex, selectEndIndex)
    );
    textareaInput.focus();

    return details;
  },
  // リスト
  insertListSymbol(textarea, insertText) {
    const textareaInput = textarea.$refs.input;

    let start = textareaInput.selectionStart;
    let end = textareaInput.selectionEnd;

    let details = textarea.value;

    // 先頭からカーソル位置まで
    const forwardText = details.slice(0, start);
    // forwardTextの中で最後に出てくる改行文字を検索する
    let lineStartIndex = forwardText.lastIndexOf(this.newLine) + 1;
    // カーソル位置以降の改行文字を検索する
    // カーソル位置は選択範囲の末尾になるため、endを指定する。範囲選択がされていない場合は、startとendが同じ値になる
    let lineEndIndex = details.indexOf(this.newLine, end);

    // カーソル位置の行の文字列を取得
    if (lineEndIndex < 0) {
      // カーソル位置以降に改行文字がない
      lineEndIndex = details.length;
    }
    let cursorLine = details.slice(lineStartIndex, lineEndIndex);

    // 記号を付ける対象は選択範囲全て
    let spCursorLine = cursorLine.split(this.newLine);
    // 選択範囲の一番先頭を基準に記号を付ける・付けないを決める
    const isAllAdd = spCursorLine[0].slice(0, insertText.length) != insertText;
    for (let i = 0; spCursorLine.length > i; i++) {
      // 番号付きリストと箇条書きリスト、リストと見出しを同時に使用できないようにする
      const hitListHxSymbol =
        this.findListSymbol(spCursorLine[i]) +
        this.findHxSynbol(spCursorLine[i]);
      if (hitListHxSymbol.length > 0) {
        if (hitListHxSymbol != insertText) {
          // 既に付いている場合は、先頭についている文字を取り除く
          spCursorLine[i] = spCursorLine[i].slice(hitListHxSymbol.length);
        } else {
          // 何もしない
        }
      }
      // 既に先頭に記号が付いているか
      if (spCursorLine[i].slice(0, insertText.length) != insertText) {
        if (isAllAdd) {
          // まだ付いていない場合は記号を付ける
          spCursorLine[i] = insertText + spCursorLine[i];
        }
      } else {
        if (!isAllAdd) {
          // 既に付いている場合は記号を取り除く
          spCursorLine[i] = spCursorLine[i].slice(insertText.length);
        }
      }
    }
    const newCursorLine = spCursorLine.join(this.newLine);

    details =
      details.slice(0, lineStartIndex) +
      newCursorLine +
      details.slice(lineEndIndex);

    let selectStartIndex = lineStartIndex;
    let selectEndIndex = selectStartIndex + newCursorLine.length;

    // カーソル位置を更新
    setTimeout(() =>
      textareaInput.setSelectionRange(selectStartIndex, selectEndIndex)
    );
    textareaInput.focus();

    return details;
  },

  findHxSynbol(strLine) {
    const h1Text = '# ';
    const h2Text = '## ';
    const h3Text = '### ';
    const h4Text = '#### ';
    const h5Text = '##### ';
    const h6Text = '###### ';

    if (strLine.slice(0, h1Text.length) == h1Text) {
      return h1Text;
    } else if (strLine.slice(0, h2Text.length) == h2Text) {
      return h2Text;
    } else if (strLine.slice(0, h3Text.length) == h3Text) {
      return h3Text;
    } else if (strLine.slice(0, h4Text.length) == h4Text) {
      return h4Text;
    } else if (strLine.slice(0, h5Text.length) == h5Text) {
      return h5Text;
    } else if (strLine.slice(0, h6Text.length) == h6Text) {
      return h6Text;
    } else {
      return '';
    }
  },
  findListSymbol(strLine) {
    const numberedText = '1. ';
    const bulletedText = '- ';

    if (strLine.slice(0, numberedText.length) == numberedText) {
      return numberedText;
    } else if (strLine.slice(0, bulletedText.length) == bulletedText) {
      return bulletedText;
    } else {
      return '';
    }
  }
};

export default markdownformat;
