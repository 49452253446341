<template>
  <v-card class="mr-5">
    <v-card-title>
      システム設定
      <v-spacer />
      <v-btn color="info" @click="startEdit">編集</v-btn>
    </v-card-title>
    <v-card-text>
      <v-sheet>
        <v-container class="px-6 text-body-2">
          <template>
            <v-row v-for="(item, key) in disp_list" :key="key">
              <v-col cols="3">{{ item.title }}</v-col>
              <v-col cols="9">{{ item.text }}</v-col>
            </v-row>
          </template>
        </v-container>
      </v-sheet>
    </v-card-text>
    <custom-dialog v-model="editedOpen">
      <template v-slot:title>システム設定</template>
      <template v-slot:body>
        <v-container class="px-6 text-body-2">
          <v-row
            class="d-flex align-center"
            no-gutters
            v-for="(item, key) in editedEvent.extension"
            :key="key"
          >
            <template v-if="item.type == 'row'">
              <v-col :cols="12">
                <p class="pa-0 ma-0">
                  <v-icon v-if="getDispStyle(config.dispStyle).useIcon">
                    mdi-cube
                  </v-icon>
                  {{ item.name }}
                </p>
              </v-col>
            </template>
            <template v-else>
              <v-col :cols="1" />
              <v-col :cols="ismobile ? 11 : 4">
                <p class="pa-0 ma-0">
                  <v-icon v-if="getDispStyle(config.dispStyle).useIcon">
                    mdi-cube-outline
                  </v-icon>
                  {{ item.name }}
                </p>
              </v-col>
              <v-col :cols="ismobile ? 12 : 7">
                <v-radio-group row v-model="item.enable" :dense="dense">
                  <v-radio :label="'有効'" :value="true" />
                  <v-radio :label="'無効'" :value="false" />
                </v-radio-group>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="endEdit">更新</v-btn>
        <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import fileOperation from '../../../utils/fileOperation';
  import token from '../../../utils/token';
  import CustomDialog from '../../components/CustomDialog.vue';
  import inputStyleUtils from '../../../utils/inputStyleUtils';
  import dateformat from '../../functions/DateFormat';

  export default {
    components: {
      CustomDialog
    },
    data: () => ({
      name: 'systemSettings',
      path: '/systemSettings',
      config: {},
      dense: true,
      ismobile: false,
      dispmode: 0,
      isError: {
        reapply: false,
        file: false,
        category: false,
        route: false,
        extra: false,
        extraText: ''
      },
      editedOpen: false,
      editedFullScreen: false,
      editedEvent: {},

      windowSize: {
        x: 0,
        y: 0
      },
      cardSize: {
        x: 0,
        y: 0
      },
      disp_list: []
    }),
    created: function () {
      if (!this.initLoading) {
        this.init();
      }
    },
    watch: {
      initLoading(val, old) {
        console.log('watch', val, old);
        if (!val) {
          this.init();
        }
      }
    },
    computed: {
      ...mapState({
        initLoading: state => state.initLoading,
        usersList: state => state.user.usersList,
        feature: state => state.feature.feature,
        systemsettings: state => state.systemsettings,
        userConfig: state => state.userConfig.userconfig
      }),
      outsideLine: {
        get() {
          return `${this.outsideLineElms[0]}-${this.outsideLineElms[1]}-${this.outsideLineElms[2]}`;
        }
      },
      cellphoneNumber: {
        get() {
          return `${this.cellphoneNumberElms[0]}-${this.cellphoneNumberElms[1]}-${this.cellphoneNumberElms[2]}`;
        }
      }
    },
    mixins: [fileOperation, token, inputStyleUtils],
    methods: {
      ...mapActions([
        'fetchFeature',
        'updateFeature',
        'fetchWorkflowTitle',
        'fetchWorkflowTag',
        'fetchInformationMarkdown',
        'updateSystemSettings',
        'fetchUserConfig'
      ]),
      init() {
        this.dispData();
      },
      isMainPage() {
        return this.$route.path === '/' + this.path;
      },
      dispData() {
        const p = [];
        p.push(this.fetchWorkflowTitle());
        p.push(this.fetchWorkflowTag());
        p.push(this.fetchInformationMarkdown());
        p.push(this.fetchFeature());

        Promise.all(p).then(() => {
          const dispData = [];

          dispData.push({
            title: '基本機能',
            text: ''
          });
          dispData.push(
            ...this.feature
              .filter(y => y.is_useLayout)
              .map(x => {
                return {
                  title: '　' + x.name,
                  text: this.getEnableText(x.enable)
                };
              })
          );
          dispData.push({
            title: 'ワークフロー詳細',
            text: ''
          });
          dispData.push({
            title: '　タイトル機能',
            text: this.getEnableText(this.systemsettings.workflow_title)
          });
          dispData.push({
            title: '　タグ機能',
            text: this.getEnableText(this.systemsettings.workflow_tag)
          });
          dispData.push({
            title: 'お知らせ詳細',
            text: ''
          });
          dispData.push({
            title: '　マークダウン機能',
            text: this.getEnableText(this.systemsettings.information_markdown)
          });
          this.disp_list = dispData;
        });
      },
      startEdit() {
        const extensions = [];

        extensions.push({
          name: '基本機能',
          feature: '',
          enable: true,
          type: 'row',
          target: 'feature'
        });
        extensions.push(
          ...this.feature
            .filter(y => y.is_useLayout)
            .map(x => {
              return {
                name: x.name,
                feature: x.feature,
                enable: x.enable,
                type: 'data',
                target: 'feature'
              };
            })
        );

        extensions.push({
          name: 'ワークフロー',
          feature: '',
          enable: true,
          type: 'row',
          target: 'systemsettings'
        });
        extensions.push({
          name: 'タイトル機能',
          feature: 'workflow_title',
          enable: this.systemsettings.workflow_title,
          type: 'data',
          target: 'systemsettings'
        });
        extensions.push({
          name: 'タグ機能',
          feature: 'workflow_tag',
          enable: this.systemsettings.workflow_tag,
          type: 'data',
          target: 'systemsettings'
        });

        extensions.push({
          name: 'お知らせ',
          feature: '',
          enable: true,
          type: 'row',
          target: 'systemsettings'
        });
        extensions.push({
          name: 'マークダウン機能',
          feature: 'information_markdown',
          enable: this.systemsettings.information_markdown,
          type: 'data',
          target: 'systemsettings'
        });
        const data = {
          extension: extensions
        };
        this.editedOpen = true;
        this.editedEvent = data;
      },
      inputCheck() {
        return true;
      },
      async endEdit() {
        //入力チェック
        if (!this.inputCheck()) return;
        try {
          const feature = {
            feature: this.editedEvent.extension
              .filter(x => x.target == 'feature' && x.type == 'data')
              .map(x => {
                return { feature: x.feature, enable: x.enable };
              })
          };
          const systemsettings = this.editedEvent.extension.filter(
            x => x.target == 'systemsettings' && x.type == 'data'
          );
          const data = systemsettings.reduce((x, y) => {
            x[y.feature] = y.enable;
            return x;
          }, feature);

          await this.updateSystemSettings(data);

          // systemsettings.map(x => {
          //   return;
          // });

          // await this.fetchFeature();
          // const newFeature = this.feature.map(x => {
          //   const result = JSON.parse(JSON.stringify(x));
          //   result.enable =
          //     feature.find(x => x.feature == result.feature)?.enable ??
          //     result.enable;
          //   return result;
          // });

          // //更新処理の場合
          // if (this.editedEvent.id != -1) {
          //   data.id = this.editedEvent.id;
          //   data.insertuser = this.editedEvent.insertuser;
          //   data.inserttime = this.editedEvent.inserttime;

          //   await this.updateFacility(data);
          // } else {
          //   await this.insertFacility(data);
          // }
          this.dispData();
          this.cancelEdit();
        } catch (err) {
          console.log(err);
        }
      },
      cancelEdit() {
        this.editedOpen = false;
      },
      async deleteData() {
        // //削除確認
        // if (!window.confirm(`この設備を削除してよろしいですか？`)) return;
        // try {
        //   const data = this.selectedEvent;
        //   await this.deleteFacility(data);
        //   this.dispData();
        //   this.cancelEdit();
        // } catch (err) {
        //   console.log(err);
        // }
      },
      getUserName(id) {
        if (!id) return '---';
        let item = this.usersList.find(item => item.id === id);
        if (!item) return '---';
        return item.disp_name;
      },
      getDateFormat(date) {
        return dateformat.autoFormat(date);
      },
      fullDateFormat(date) {
        return dateformat.fullFormat(date);
      },
      getEnableText(value) {
        return value ? '有効' : '無効';
      }
    }
  };
</script>

<style lang="css">
  .rowBackGround {
    color: darkgray;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
