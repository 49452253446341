<template>
  <v-card class="mx-1 mt-3" color="background_sub">
    <v-container>
      <v-row class="pa-0" justify="center" align-content="center">
        <v-col>
          <v-toolbar-title class="pt-4 pl-5">{{ label }}</v-toolbar-title>
        </v-col>
        <v-col>
          <v-select
            class="pt-4 pr-5"
            :prepend-icon="useIcon ? 'mdi-account-multiple' : ''"
            label="グループ"
            item-text="group_name"
            item-value="id"
            :items="GetUserGroup()"
            v-model="group_id"
            @change="group_change"
            :outlined="outlined"
            dense
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col cols="6">
          <v-list style="min-height: 200px; max-height: 200px" class="overflow-y-auto" :outlined="outlined" dense>
            <v-list-item v-for="(item, index) in view_sel_users" :key="index" link @click="removeUserItem(item)">
              <!-- <v-list-item-avatar>
              <v-icon left :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title v-if="dispNumber">{{ index + 1 }}.{{ item.disp_name }}</v-list-item-title>
                <v-list-item-title v-else>
                  {{ item.disp_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-list
            style="min-height: 200px; max-height: 200px; max-width: 600px"
            class="overflow-y-auto"
            :outlined="outlined"
            dense
          >
            <v-list-item v-for="(item, index) in this.view_src_users" :key="index" link @click="selectedUserItem(item)">
              <!-- <v-list-item-avatar>
              <v-icon left :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import token from '../../utils/token';

  export default {
    components: {},
    name: 'MultiUserSelectList',
    props: {
      data: [],
      label: {},
      dispNumber: Boolean,
      outlined: {
        type: Boolean,
        default: true
      },
      dense: {
        type: Boolean,
        default: true
      },
      useIcon: {
        type: Boolean,
        default: true
      },
      useTitleLabel: {
        type: Boolean,
        default: false
      },
      ismobile: {
        type: Boolean,
        default: true
      },
      canEmpty: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        view_sel_users: [],
        view_src_users: [],
        group_id: 0
      };
    },
    computed: {
      ...mapState({
        usersList: state => state.user.usersList,
        usergroup: state => state.userGroup.usergroup,
        filedata: state => state.fileData.filedata,
        userconfig: state => state.userConfig.userconfig
      })
    },
    watch: {
      data: function () {
        this.view_sel_users.splice(0);

        //初期選択のuser
        this.view_sel_users.push(...this.data.map(x => this.usersList.find(y => y.id === x)).filter(x => x));
      }
    },

    created: function () {},
    mounted: function () {
      //groupの初期選択
      this.group_id = 1;
      let sel_group = this.GetUserGroup().find(item => item.user_id.find(item => item === this.getUserId()));
      if (sel_group) {
        this.group_id = sel_group.id;
      }
      this.group_change();

      if (this.view_sel_users.length === 0) {
        this.view_sel_users.splice(0);

        //初期選択のuser
        this.data.forEach(user_id => {
          let addItem = this.usersList
            .filter(x => x.enable)
            .find(item => item.id === user_id || item.id === user_id?.id);
          if (addItem) {
            this.view_sel_users.push(addItem);
          }
        });
      }
    },
    mixins: [token],
    methods: {
      ...mapActions([]),
      onResize() {},
      group_change() {
        let sel_group = this.GetUserGroup().find(item => item.id === this.group_id);
        this.view_src_users = [];
        this.usersList
          .filter(x => x.enable)
          .forEach(item => {
            if (sel_group.id <= 0) {
              //全て
              this.view_src_users.push(item);
            } else {
              if (sel_group.user_id.find(user_id => user_id === item.id)) {
                this.view_src_users.push(item);
              }
            }
          });
      },
      selectedUserItem(item) {
        let find_user = false;

        this.view_sel_users.forEach(item2 => {
          find_user = find_user || item.id == item2.id;
        });
        if (!find_user) {
          this.view_sel_users.push(item);
        }
        //選択データの同期
        this.unionData();
      },
      removeUserItem(item) {
        this.view_sel_users.splice(this.view_sel_users.indexOf(item), 1);
        if (!this.canEmpty && this.view_sel_users.length === 0) {
          let addItem = this.usersList.filter(x => x.enable).find(item => item.id === this.getUserId());
          this.view_sel_users.push(addItem);
        }
        //選択データの同期
        this.unionData();
      },
      unionData() {
        this.data.splice(0);
        this.view_sel_users.forEach(item => this.data.push(item.id));
      },
      GetUserGroup() {
        const ret = [];
        ret.push({ id: -1, group_name: '全て', user_id: [] });
        ret.push(...this.usergroup);
        return ret;
      }
    }
  };
</script>
