var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"app":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"contain":"","src":_vm.getImageUrl(),"transition":"scale-transition","max-height":"50","max-width":_vm.company_log_width,"light":true},on:{"click":_vm.logo_click}})],1)],1),(_vm.isDevMode())?[_c('v-btn',{on:{"click":_vm.devbtn_click}},[_c('v-icon',{staticClass:"mdi-spin",attrs:{"dark":""}},[_vm._v("mdi-cat")]),_vm._v(" 開発環境 ")],1)]:_vm._e(),_c('v-spacer'),(_vm.windowSize.x < 1200)?[_c('activity'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.location.reload()}}},'v-btn',attrs,false),on),[_vm._v(" menu ")])]}}],null,false,827559034)},[_c('v-list',[_vm._l((_vm.pages),function(item,index){return [(item.type === 'btn' && item.visible)?_c('v-list-item',{key:index,attrs:{"to":item.url}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):(item.type === 'link' && item.visible)?_c('v-list-item',{key:index + 'A',attrs:{"href":item.url,"target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):(item.type === 'account' && item.visible)?_c('v-list-item',{key:index + 'B',attrs:{"to":item.url}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.disp_name))])],1):(item.type === 'logout' && item.visible)?_c('v-list-item',{key:index + 'C',on:{"click":_vm.dologout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):(item.type === 'line' && item.visible)?_c('v-divider',{key:index + 'D'}):_vm._e()]})],2)],1)]:[(_vm.useNewMenu || _vm.isDevMode())?[_vm._l((_vm.pages),function(item,key){return [(item.visible && !item.in_menu)?[((_vm.useNewMenu || _vm.isDevMode()) && item.type === 'activity')?_c('activity',{key:key + 'activity'}):(item.type === 'btn')?_c('v-tooltip',{key:key,attrs:{"open-delay":"100","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"to":item.url,"disabled":!item.enabled,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]):(item.type === 'link')?_c('v-btn',{key:key + 'E',staticClass:"mr-2",attrs:{"href":item.url,"target":"_blank","text":"","disabled":!item.enabled}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('v-icon',[_vm._v("mdi-open-in-new")])],1):(item.type === 'line')?_c('v-divider',{key:key + 'G',attrs:{"vertical":""}}):(item.type === 'account')?_c('v-menu',{key:key + 'F',attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"deep-purple accent-4","content":_vm.alert.count,"offset-x":"12","offset-y":"12"},model:{value:(_vm.alert.visible),callback:function ($$v) {_vm.$set(_vm.alert, "visible", $$v)},expression:"alert.visible"}},[_c('v-avatar',{attrs:{"color":"grey darken-1","size":"32"}},[_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.user.disp_name)}})],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":item.url}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-title',[_vm._v("個人設定")])],1),(_vm.user.admin)?_c('v-list-item',{attrs:{"to":"/admin/settings"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-title',[_vm._v("管理設定")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.setDispNotificationDialog(true)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-bell-ring")])],1),_c('v-list-item-title',[_vm._v("通知設定")])],1),_c('v-list-item',{on:{"click":_vm.dologout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("ログアウト")])],1)],1)],1):_vm._e()]:_vm._e()]}),_c('v-menu',{attrs:{"min-width":"400px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,false,2322017526)},[_c('v-card',{attrs:{"min-width":"120px"}},[_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-row',{staticClass:"pa-1 ma-0"},[_vm._v(" 機能"),_c('v-spacer')],1),_c('v-divider'),_c('v-container',[_c('v-row',{staticClass:"d-flex align-center py-1",attrs:{"no-gutters":""}},[_vm._l((_vm.pages),function(item,key){return [(item.visible && item.in_menu)?_c('v-col',{key:key,staticClass:"pa-1",attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(item.type === 'activity')?_c('v-card',{attrs:{"elevation":hover ? 12 : 2}},[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('activity')],1),_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.title))])])],1):(item.type === 'btn')?_c('v-card',{attrs:{"elevation":hover ? 12 : 2,"to":item.url}},[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1),_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.title))])])],1):(item.type === 'link')?_c('v-card',{attrs:{"elevation":hover ? 12 : 2,"href":item.url,"target":"_blank"}},[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1),_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.title))])])],1):(item.type === 'account')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({attrs:{"elevation":hover ? 12 : 2}},'v-card',attrs,false),on),[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1),_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.user.disp_name)+" ")])])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":item.url}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-account')}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('個人設定')}})],1),(_vm.user.admin)?_c('v-list-item',{attrs:{"to":"/admin/settings"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('管理設定')}})],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.setDispNotificationDialog(true)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-bell-ring')}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('通知設定')}})],1),_c('v-list-item',{on:{"click":_vm.dologout}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-logout')}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('ログアウト')}})],1)],1)],1):_vm._e()]}}],null,true)})],1):_vm._e()]})],2)],1)],1)],1)],1)]:[_vm._l((_vm.pages),function(item,key){return [(item.visible)?[((_vm.useNewMenu || _vm.isDevMode()) && item.type === 'activity')?_c('activity',{key:key + 'activity'}):(item.type === 'btn')?_c('v-tooltip',{key:key,attrs:{"open-delay":"100","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"to":item.url,"disabled":!item.enabled,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]):(item.type === 'link')?_c('v-btn',{key:key + 'E',staticClass:"mr-2",attrs:{"href":item.url,"target":"_blank","text":"","disabled":!item.enabled}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('v-icon',[_vm._v("mdi-open-in-new")])],1):(item.type === 'account')?_c('v-menu',{key:key + 'F',attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"deep-purple accent-4","content":_vm.alert.count,"offset-x":"12","offset-y":"12"},model:{value:(_vm.alert.visible),callback:function ($$v) {_vm.$set(_vm.alert, "visible", $$v)},expression:"alert.visible"}},[_c('v-avatar',{attrs:{"color":"grey darken-1","size":"32"}},[_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('span',{domProps:{"innerHTML":_vm._s(_vm.user.disp_name)}})],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":item.url}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-account')}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('個人設定')}})],1),(_vm.user.admin)?_c('v-list-item',{attrs:{"to":"/admin/settings"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('管理設定')}})],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.setDispNotificationDialog(true)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-bell-ring')}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('通知設定')}})],1),_c('v-list-item',{on:{"click":_vm.dologout}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-logout')}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('ログアウト')}})],1)],1)],1):(item.type === 'line')?_c('v-divider',{key:key + 'G',attrs:{"vertical":""}}):_vm._e()]:_vm._e()]})]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }