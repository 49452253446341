<template>
  <v-sheet class="frame" v-resize="onResize" id="library" ref="card">
    <v-toolbar class="frame" flat>
      <v-btn :to="path" tile text class="pa-0">
        <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon">mdi-volcano</v-icon>
        <div class="text-decoration-underline text-body-1" style="width: 7em">施設利用状況</div>
      </v-btn>
      <v-select
        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-tree' : ''"
        :items="facilityFilterList"
        item-text="name"
        :outlined="getDispStyle(config.dispStyle).outlined"
        :dense="dense"
        v-model="selectedFacilityFilter"
        hide-details="auto"
        style="max-width: 300px"
        return-object
      >
        <template v-slot:label>
          <div>表示設備</div>
        </template>
      </v-select>
      <v-spacer />
      <v-btn @click="reload()" small>
        <v-icon>mdi-reload</v-icon>
        <div>{{ reloaddatetime }}時点</div>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" v-for="(item, index) in dispData" :key="index">
          <v-card height="100%">
            <v-card-title>{{ item.facility.disp_name }}</v-card-title>
            <v-card-subtitle>{{ item.groupName }}</v-card-subtitle>
            <v-card-text>
              <template v-if="item.isUsered">
                <v-card class="red lighten-4 subtitle-1 text-center"> 利用中</v-card>
              </template>
              <template v-else>
                <v-card class="blue lighten-4 subtitle-1 text-center">空き</v-card>
              </template>
              <v-container>
                <v-row v-for="(rItem, rIndex) in item.schedule" :key="rIndex" no-gutters>
                  <v-col cols="12">{{ rItem.dateText }}</v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="11">{{ rItem.title }}</v-col>
                  <v-col cols="11" class="text-right">{{ rItem.userName }}</v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import dateformat from '../functions/DateFormat';
import DateUtils from '../functions/DateUtils';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';
import scroll from '../../utils/scroll';

export default {
  components: {},
  data: () => ({
    name: 'facilityInfo',
    path: '/facilityInfo',
    config: {},
    dense: true,
    ismobile: false,
    dispData: [],
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    reloaddatetime: '',
    facilityFilterList: [{ id: -1, name: '全て', group_id: -1, facility_id: -1 }],
    selectedFacilityFilter: { id: -1, name: '全て', group_id: -1, facility_id: -1 }
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    reLoading() {
      this.reload();
    },
    selectedFacilityFilter() {
      this.reload();
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      reLoading: state => state.reLoading,
      usersList: state => state.user.usersList,
      facilityList: state => state.facility.facility,
      facilitygroup: state => state.facilityGroup.facilityGroup,
      schedulesList: state => state.schedules.schedulesList,
      userConfig: state => state.userConfig.userconfig
    })
  },
  mixins: [fileOperation, token, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'fetchUserConfig',
      'upsertUserConfig',
      'fetchScheduleList',
      'fetchScheduleItem',
      'fetchUserList',
      'fetchUserGroup',
      'fetchFacility',
      'fetchFacilityGroup'
    ]),
    async init() {
      await this.dispFacilityList();
      await this.dispFacilityData();
      this.timer_tick();
    },
    reload() {
      //データ取得
      this.dispFacilityData();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.ismobile = document.getElementById('library').clientWidth < 800;
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    async dispFacilityList() {
      // 施設情報の取得
      await this.fetchFacility();
      await this.fetchFacilityGroup();

      const filterList = [{ id: -1, name: '全て', group_id: -1, facility_id: -1 }];

      filterList.push(
        ...this.facilitygroup.map(x => ({
          id: x.id * 10000,
          name: x.group_name + '(G)',
          group_id: x.id,
          facility_id: -1
        }))
      );
      filterList.push(
        ...this.facilityList
          .filter(x => x.enable)
          .map(x => ({ id: x.id, name: x.disp_name, group_id: -1, facility_id: x.id }))
      );

      this.facilityFilterList = filterList;
    },
    async dispFacilityData() {
      const now = DateUtils.getNow();
      const today = DateUtils.getToday();
      this.reloaddatetime = dateformat.autoFormat(now);

      // 全施設検索
      const data = {
        start_date: DateUtils.addDay(today, -1),
        end_date: DateUtils.addDay(today, 1),
        user_id: [],
        facility_id: this.facilityList.map(x => x.id)
      };
      // 絞りこみ条件
      const filter = {
        facility_id: this.selectedFacilityFilter.facility_id,
        group_id: this.selectedFacilityFilter.group_id
      };

      // スケジュール情報の取得
      const schedulesList = (await this.fetchScheduleList(data)).data;

      // 設備情報
      const facilities = this.facilityList
        .filter(x => x.enable)
        .filter(x => x.id == filter.facility_id || filter.facility_id == -1)
        .map(x => ({
          ...x,
          group_ids: this.facilitygroup.filter(y => y.facility_id.some(z => z == x.id)).map(y => y.id),
          sortGroupId: this.facilitygroup.find(y => y.facility_id.some(z => z == x.id))?.id ?? 99999
        }))
        .filter(x => x.group_ids.some(y => y == filter.group_id) || filter.group_id == -1)
        .sort((a, b) => a.sortGroupId + a.id - (b.sortGroupId + b.id));

      const dispData = facilities.map(x => {
        const result = {
          facility: x,
          groupName: x.group_ids.map(y => this.facilitygroup.find(z => z.id == y)?.group_name ?? '---').join('、'),
          schedule: this.filterScheduleData(schedulesList, x.id, now),
          isUsered: false
        };

        result.isUsered = result.schedule.some(
          y => !y.data.timed || (y.data.start_date <= now && y.data.end_date > now)
        );

        return result;
      });
      this.dispData = dispData;
    },
    filterScheduleData(schedulesList, facility_id, now) {
      // 翌朝8時の開始までの分を取得
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 8, 0, 0, 0);
      console.log(maxDate);

      const facilityQuery = schedulesList
        .filter(y => y.facility_id.some(z => z == facility_id))
        .map(x => ({ ...x, start_date: new Date(x.start_date), end_date: new Date(x.end_date) }));
      const dateQuery = facilityQuery.filter(x => x.end_date >= now && x.start_date < maxDate);
      //繰り返し予定の本体は表示しない
      const repoeatQuery = dateQuery.filter(x => !(x.repeat_pattern > 0) || x.id == -1);
      repoeatQuery.sort((a, b) => a.start_date.getTime() - b.start_date.getTime());

      return repoeatQuery.map(x => {
        const result = { id: x.id, dateText: '', title: '', userName: '', data: { ...x } };
        if (x.timed) {
          const startDate =
            (x.start_date.getDate() > now.getDate() ? dateformat.format(x.start_date, 'MM/DD') + ' ' : '') +
            dateformat.format(x.start_date, 'HH:mm');
          const endDate = dateformat.format(x.end_date, 'HH:mm');
          result.dateText = `${startDate}-${endDate}`;
        } else {
          // 終日
          result.dateText = '終日';
        }
        if (x.public_flg >= 1) {
          result.title = '🔒予定あり';
        } else {
          result.title = x.title;
        }
        result.userName = this.usersList.find(y => y.id === x.user_id[0]).disp_name;
        if (x.user_id.length > 1) {
          result.userName += ' 他' + (x.user_id.length - 1) + '名';
        }
        return result;
      });
    },
    timer_tick() {
      setInterval(() => this.reload(), 60 * 1000);
    }
  }
};
</script>
<style scoped>
</style>
