import http from '../../utils/httpClient';

const state = {
  workflow3: []
};

const mutations = {
  setWorkflow3(state, workflow) {
    state.workflow3 = workflow;
  }
};

const actions = {
  async fetchWorkflow3(context, data) {
    await http.get('workflow3', { params: data }).then(res => {
      context.commit('setWorkflow3', res.data);
    });
  },
  async fetchWorkflow3Item(context, data) {
    return await http.get(`workflow3/item/${data.id}`);
  },
  async fetchWorkflow3State(context, data) {
    return await http.get(`workflow3/state/${data.id}`);
  },
  async insertWorkflow3Apply(context, data) {
    return await http.post('workflow3/apply/0', data);
  },
  async insertWorkflow3Reapply(context, data) {
    return await http.post('workflow3/apply/1', data);
  },
  async insertWorkflow3Accept(context, data) {
    return await http.post('workflow3/accept', data);
  },
  async deleteWorkflow3(context, data) {
    return await http.post(`workflow3/delete`, data);
  },
  async workflowconverv2tov3(context, data) {
    return await http.post(`workflow3/converv2tov3`, data);
  },
  async workflowconverv3_1(context, data) {
    return await http.post(`workflow3/converv3_1`, data);
  }
  /////////////////////////////////////////////////////////////
};

export default {
  state,
  mutations,
  actions
};
