<template>
  <v-card class="mr-5">
    <v-card-title>
      ワークフロー様式管理
      <v-btn color="info" class="pa-2 ml-6" @click="startEdit">追加</v-btn>

      <v-spacer />
      <!-- ワークフロー様式検索フィールド -->
      <v-text-field v-model="search" append-icon="mdi-magnify" label="ワークフロー様式検索" single-line hide-details />
    </v-card-title>
    <!-- ワークフロー様式リスト表示テーブル -->
    <v-data-table
      :headers="getHeaders()"
      :items="listitem"
      item-key="id"
      :search="search"
      class="ma-4"
      :mobile-breakpoint="0"
      @click:row="showEvent"
    >
      <template v-slot:[`item.updatetime`]="{ item }">
        {{ getDateFormat(item.updatetime) }}
      </template>
      <template v-slot:[`item.updateuser`]="{ item }">
        {{ getUserName(item.updateuser) }}
      </template>
    </v-data-table>
    <custom-dialog v-model="selectedOpen">
      <template v-slot:title>ワークフロー様式情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">ワークフロー様式名称</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.disp_name"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">ワークフロー様式名称</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col cols="12">
              <v-card class="mx-1 mt-3" color="background_sub">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="1">
                      <p class="mb-0 pr-2 text-center">順序</p>
                    </v-col>
                    <v-col cols="5">
                      <p class="mb-0 text-center">項目名</p>
                    </v-col>
                    <v-col cols="2">
                      <p class="mb-0 text-center">必須</p>
                    </v-col>
                    <v-col cols="4">
                      <p class="mb-0 text-center">入力種別</p>
                    </v-col>
                  </v-row>

                  <template>
                    <v-row v-for="(item, index) in selectedEvent.rows" :key="index" no-gutters>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                      <v-col cols="1">
                        <p class="mb-0 pr-2 text-right">{{ item.row }}</p>
                      </v-col>
                      <v-col cols="5">
                        <p class="mb-0">
                          <v-icon>{{ item.icon }}</v-icon
                          >{{ item.name }}
                        </p>
                      </v-col>
                      <v-col cols="2">
                        <p class="mb-0 text-center">
                          {{ getRequiredName(item.required) }}
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="mb-0">{{ getRowTypeName(item.type) }}</p>
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">表示優先度</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.sort"
                readonly
                hide-details="auto"
                type="number"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">順序</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card class="mx-1 mt-3" color="background_sub">
          <v-container class="px-6 text-body-2">
            <v-row class="pa-0">
              <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">作成者</v-col>

              <v-col class="pa-0">{{ getUserName(selectedEvent.insertuser) }}</v-col>
              <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.inserttime) }}</v-col>
            </v-row>
            <v-row><v-divider /></v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">更新者</v-col>

              <v-col class="pa-0">{{ getUserName(selectedEvent.updateuser) }}</v-col>
              <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.updatetime) }}</v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="startEditing"> 編集 </v-btn>
        <v-btn color="warning" @click="deleteData"> 削除 </v-btn>
        <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog v-model="editedOpen">
      <template v-slot:title>ワークフロー様式情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">ワークフロー様式名称</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.disp_name"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">ワークフロー様式名称</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col cols="12">
              <v-card class="mx-1 mt-3" color="background_sub">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12"> </v-col>
                  </v-row>

                  <v-row v-for="(item, index) in editedEvent.rows" :key="index" no-gutters>
                    <v-col cols="12"><v-divider class="pa-2" /></v-col>
                    <v-col cols="1">
                      <v-text-field
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        label="序列"
                        v-model="item.row"
                        type="number"
                        style="text-align: right"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        label="項目名"
                        v-model="item.name"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        label="必須"
                        v-model="item.required"
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-autocomplete
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        :items="mdiIcons"
                        label="アイコン"
                        :prepend-inner-icon="item.icon"
                        v-model="item.icon"
                      >
                        <template v-slot:item="data"
                          ><v-icon>{{ data.item }}</v-icon
                          >{{ data.item }}</template
                        >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="1"> </v-col>
                    <v-col cols="10">
                      <v-select
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        label="種別"
                        item-text="disp_name"
                        item-value="type"
                        :items="templateRowTypeList"
                        v-model="item.type"
                      />
                    </v-col>
                    <v-col cols="1"> </v-col>
                    <template v-if="item.type == 'VTextField'">
                      <v-col cols="1"> </v-col>
                      <v-col cols="2">
                        <v-text-field
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="入力文字数"
                          v-model="item.counter"
                          type="number"
                          style="text-align: right"
                        />
                      </v-col>
                      <v-col cols="1"> </v-col>
                      <v-col cols="3">
                        <v-select
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="入力規則"
                          item-text="disp_name"
                          item-value="type"
                          :items="templateRowValidationList"
                          v-model="item.validation"
                        />
                      </v-col>
                      <v-col cols="5"> </v-col>
                      <template v-if="item.validation == 'number'">
                        <v-col cols="1"> </v-col>
                        <v-col cols="2">
                          <v-text-field
                            :outlined="getDispStyle(config.dispStyle).outlined"
                            :dense="dense"
                            label="単位"
                            v-model="item.unit"
                          />
                        </v-col>
                        <v-col cols="1"> </v-col>
                        <v-col cols="2">
                          <v-text-field
                            :outlined="getDispStyle(config.dispStyle).outlined"
                            :dense="dense"
                            label="桁数(n,n)"
                            v-model="item.digits"
                          />
                        </v-col>
                        <v-col cols="1"> </v-col>
                        <v-col cols="2">
                          <v-text-field
                            :outlined="getDispStyle(config.dispStyle).outlined"
                            :dense="dense"
                            label="最小値"
                            v-model="item.min_value"
                            type="number"
                            style="text-align: right"
                          />
                        </v-col>
                        <v-col cols="1"> </v-col>
                        <v-col cols="2">
                          <v-text-field
                            :outlined="getDispStyle(config.dispStyle).outlined"
                            :dense="dense"
                            label="最大値"
                            v-model="item.max_value"
                            type="number"
                            style="text-align: right"
                          />
                        </v-col>
                      </template>
                      <v-col cols="1"> </v-col>

                      <v-col cols="10">
                        <v-text-field
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="デフォルト値"
                          v-model="item.default_value"
                        />
                      </v-col>
                      <v-col cols="1"> </v-col>
                    </template>
                    <template v-else-if="item.type == 'VTextarea'">
                      <v-col cols="1"> </v-col>
                      <v-col cols="3">
                        <v-text-field
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="表示行数"
                          v-model="item.rows"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="1"> </v-col>
                      <v-col cols="3">
                        <v-text-field
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="入力文字数"
                          v-model="item.counter"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="4"> </v-col>
                    </template>
                    <template v-else-if="item.type == 'VSelect'">
                      <v-col cols="1"> </v-col>
                      <v-col cols="10">
                        <v-text-field
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="リストの内容(カンマ区切り)"
                          v-model="item.list_items_edit"
                        />
                      </v-col>
                      <v-col cols="1"> </v-col>
                      <v-col cols="1"> </v-col>
                      <v-col cols="4">
                        <v-text-field
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="デフォルト値(index指定)"
                          v-model="item.default_value"
                          type="number"
                          style="text-align: right"
                        />
                      </v-col>
                      <v-col cols="7"> </v-col>
                    </template>
                    <template v-else-if="item.type == 'DatePicker'">
                      <v-col cols="1"> </v-col>
                      <v-col cols="4">
                        <v-select
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="デフォルト値"
                          item-text="disp_name"
                          item-value="type"
                          :items="templateRowDateDefaultValueList"
                          v-model="item.default_value"
                        />
                      </v-col>
                      <v-col cols="1"> </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="空欄モード"
                          v-model="item.dt_empty"
                        />
                      </v-col>
                      <v-col cols="3"> </v-col>
                    </template>
                    <template v-else-if="item.type == 'TimePicker'">
                      <v-col cols="1"> </v-col>
                      <v-col cols="4">
                        <v-combobox
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="デフォルト値"
                          item-text="type"
                          item-value="type"
                          :items="templateRowDateDefaultValueList"
                          v-model="item.default_value"
                        />
                      </v-col>
                    </template>
                    <template v-else-if="item.type == 'date_month'">
                      <!-- 設定不可 -->
                    </template>
                    <template v-else-if="item.type == 'date_span'">
                      <v-col cols="1"> </v-col>
                      <v-col cols="4">
                        <v-combobox
                          :outlined="getDispStyle(config.dispStyle).outlined"
                          :dense="dense"
                          label="デフォルト値"
                          item-text="type"
                          item-value="type"
                          :items="templateRowDateDefaultValueList"
                          v-model="item.default_value"
                        />
                      </v-col>
                      <v-col cols="7"> </v-col>
                    </template>
                    <template v-else> </template>
                    <v-col cols="1"> </v-col>
                    <v-col cols="6">
                      <v-btn @click="btnUpItemClick(index)" small><v-icon>mdi-arrow-up-bold</v-icon></v-btn>
                      <v-btn @click="btnDownItemClick(index)" small><v-icon>mdi-arrow-down-bold</v-icon></v-btn>
                    </v-col>
                    <v-col cols="3"> </v-col>
                    <v-col cols="2">
                      <v-btn color="warning" @click="btnDeleteItemClick(index)" small
                        ><v-icon>mdi-delete</v-icon>削除</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-row no-gutters><v-divider /></v-row>
                  <v-row no-gutters>
                    <v-col cols="1">
                      <v-btn color="info" @click="btnAddItemClick" fab x-small dark
                        ><v-icon dark>mdi-plus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">順序</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.sort"
                hide-details="auto"
                type="number"
                readonly
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">表示優先度</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="endEdit">登録</v-btn>
        <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import CustomDialog from '../../components/CustomDialog.vue';
import inputStyleUtils from '../../../utils/inputStyleUtils';
import dateformat from '../../functions/DateFormat';
import mdiIcons from '../../functions/MdiIcons';
export default {
  components: {
    CustomDialog
  },
  data: () => ({
    name: 'workflowtemplateSettings',
    path: '/workflowtemplateSettings',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    dispmode: 0,
    search: '',
    listitem: [],
    isError: {
      reapply: false,
      file: false,
      category: false,
      route: false,
      extra: false,
      extraText: ''
    },
    selectedOpen: false,
    selectedFullScreen: false,
    selectedEvent: {},
    editedOpen: false,
    editedFullScreen: false,
    editedEvent: {},

    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    templateRowTypeList: [
      {
        id: 1,
        type: 'VTextField',
        disp_name: 'テキスト入力'
      },
      {
        id: 2,
        type: 'VTextarea',
        disp_name: 'テキスト入力（複数行）'
      },
      {
        id: 3,
        type: 'VSelect',
        disp_name: 'ドロップダウン選択'
      },
      {
        id: 4,
        type: 'DatePicker',
        disp_name: '日付入力'
      },
      {
        id: 5,
        type: 'TimePicker',
        disp_name: '時間入力'
      },
      {
        id: 6,
        type: 'date_month',
        disp_name: '年月入力'
      },
      {
        id: 7,
        type: 'date_span',
        disp_name: '日付期間入力'
      }
    ],
    templateRowValidationList: [
      {
        id: 0,
        type: '',
        disp_name: '通常入力'
      },
      {
        id: 1,
        type: 'number',
        disp_name: '数値入力'
      }
    ],
    templateRowDateDefaultValueList: [
      {
        id: 1,
        type: 'today',
        disp_name: '今日'
      },
      {
        id: 2,
        type: 'tomorrow',
        disp_name: '翌日'
      },
      {
        id: 3,
        type: 'yesterday',
        disp_name: '昨日'
      },
      {
        id: 4,
        type: 'last_week',
        disp_name: '先週'
      },
      {
        id: 5,
        type: 'next_monday',
        disp_name: '次の月曜日'
      },
      {
        id: 6,
        type: 'next_saturday',
        disp_name: '次の土曜日'
      }
    ],
    templateRowTimeDefaultValueList: [
      {
        id: 1,
        type: 'now',
        disp_name: '現在時刻'
      }
    ],
    mdiicons: []
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      workflowTemplate: state => state.workflowTemplate.workflowTemplate,
      userConfig: state => state.userConfig.userconfig
    }),
    outsideLine: {
      get() {
        return `${this.outsideLineElms[0]}-${this.outsideLineElms[1]}-${this.outsideLineElms[2]}`;
      }
    },
    cellphoneNumber: {
      get() {
        return `${this.cellphoneNumberElms[0]}-${this.cellphoneNumberElms[1]}-${this.cellphoneNumberElms[2]}`;
      }
    }
  },
  mixins: [fileOperation, token, inputStyleUtils],
  methods: {
    ...mapActions([
      'fetchWorkflowTemplate',
      'updateWorkflowTemplate',
      'insertWorkflowTemplate',
      'deleteWorkflowTemplate',
      'fetchWorkflowTemplateRow',
      'fetchUserConfig'
    ]),
    init() {
      this.mdiIcons = mdiIcons.getMdiIcons();
      this.dispData();
    },
    getHeaders() {
      const disp_id = {
        text: 'ID',
        value: 'id'
      };
      const disp_name = {
        text: '名前',
        value: 'disp_name'
      };
      const sort = {
        text: '順序',
        value: 'sort',
        align: 'end',
        width: '80px'
      };
      const updateuser = {
        text: '更新者',
        value: 'updateuser',
        width: '120px'
      };
      const updatetime = {
        text: '更新日時',
        value: 'updatetime',
        align: 'end',
        width: '150px'
      };
      return [disp_id, disp_name, sort, updateuser, updatetime];
    },
    isMainPage() {
      return this.$route.path === '/' + this.path;
    },
    dispData() {
      const p = [];
      p.push(this.fetchWorkflowTemplate());

      Promise.all(p).then(() => {
        this.listitem = this.workflowTemplate;
      });
    },
    showEvent(eventData) {
      const data = JSON.parse(JSON.stringify(eventData));

      this.selectedOpen = true;
      this.selectedEvent = data;
      this.editedOpen = false;
      this.editedEvent = {};
    },
    startEdit() {
      const data = {
        id: -1,
        disp_name: '',
        sort: 0,
        rows: [],
        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date()
      };
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedOpen = true;
      this.editedEvent = data;
    },
    startEditing() {
      const data = JSON.parse(JSON.stringify(this.selectedEvent));
      data.rows = data.rows.map(x => ({ ...x, list_items_edit: this.getListItemText(x.list_items) }));

      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedEvent = data;
      this.editedOpen = true;
    },
    getListItemText(value) {
      if (value == '') return '';
      const itemObject = JSON.parse(value);
      return itemObject.map(x => x?.name ?? '').join();
    },
    getListItemData(value) {
      if (value == '') return [];
      const itemObject = value.split(',');
      return itemObject.map((x, i) => ({ id: i, name: x }));
    },
    inputCheck() {
      return true;
    },
    async endEdit() {
      //入力チェック
      if (!this.inputCheck()) return;
      try {
        const rows =
          this.editedEvent.rows.map(x => {
            x.list_items = JSON.stringify(this.getListItemData(x.list_items_edit));
            // リスト値の解決
            if (x.default_value?.disp_name) {
              x.default_value = x.default_value.disp_name;
            }
            return x;
          }) ?? [];

        const data = {
          disp_name: this.editedEvent.disp_name,
          sort: this.editedEvent.sort,
          insertuser: this.getUserId(),
          inserttime: new Date(),
          updateuser: this.getUserId(),
          updatetime: new Date(),
          rows: rows
        };
        //更新処理の場合
        if (this.editedEvent.id != -1) {
          data.id = this.editedEvent.id;
          data.insertuser = this.editedEvent.insertuser;
          data.inserttime = this.editedEvent.inserttime;

          await this.updateWorkflowTemplate(data);
        } else {
          await this.insertWorkflowTemplate(data);
        }
        this.dispData();
        this.cancelEdit();
      } catch (err) {
        console.log(err);
      }
    },
    cancelEdit() {
      this.selectedOpen = false;
      this.editedOpen = false;
    },
    async deleteData() {
      //削除確認
      if (!window.confirm(`このワークフロー様式を削除してよろしいですか？`)) return;
      try {
        const data = this.selectedEvent;
        await this.deleteWorkflowTemplate(data);
        this.dispData();
        this.cancelEdit();
      } catch (err) {
        console.log(err);
      }
    },
    btnUpItemClick(index) {
      const rows = this.editedEvent.rows;
      if (index > 0) {
        [rows[index - 1], rows[index]] = [rows[index], rows[index - 1]];
      }
      this.editedEvent.rows = rows.map((x, i) => ({ ...x, row: i + 1 }));
    },
    btnDownItemClick(index) {
      const rows = this.editedEvent.rows;
      if (index + 1 < rows.length) {
        [rows[index], rows[index + 1]] = [rows[index + 1], rows[index]];
      }
      this.editedEvent.rows = rows.map((x, i) => ({ ...x, row: i + 1 }));
    },
    btnDeleteItemClick(index) {
      const rows = this.editedEvent.rows;
      rows.splice(index, 1);
      this.editedEvent.rows = rows.map((x, i) => ({ ...x, row: i + 1 }));
    },
    btnAddItemClick() {
      const rows = this.editedEvent.rows;

      rows.push(this.createEmptyRowItem(this.editedEvent));
      this.editedEvent.rows = rows.map((x, i) => ({ ...x, row: i + 1 }));
    },
    createEmptyRowItem(template) {
      return {
        id: -1,
        template_id: template.id,
        row: 0,
        name: '',
        icon: '',
        type: '',
        rows: 0,
        counter: 0,
        unit: '',
        validation: '',
        digits: '',
        min_value: 0,
        max_value: 0,
        required: true,
        default_value: '',
        dt_empty: false,
        list_items: '',
        list_items_edit: ''
      };
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    },
    getRowTypeName(type) {
      return this.templateRowTypeList.find(x => x.type == type)?.disp_name ?? '(未定義)';
    },
    getRequiredName(required) {
      return required ? '必須' : '';
    }
  }
};
</script>

<style lang="css">
.rowBackGround {
  color: darkgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
