<template>
  <v-sheet class="frame" v-resize="onResize" id="workflow3">
    <v-toolbar class="frame" flat>
      <v-btn :to="path" tile text class="pa-0">
        <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon">mdi-clipboard-flow</v-icon>
        <div class="text-decoration-underline text-body-1" style="width: 7.7em">ワークフロー</div>
      </v-btn>
      <v-select
        prepend-icon="mdi-tree"
        label="申請区分"
        :items="this.getDispmodeList()"
        item-text="disp_name"
        item-value="id"
        v-model="dispmode"
        style="max-width: 250px"
        dense
        v-show="disp_cols === 12 && !ismobile"
        class="mt-3"
        @change="setDispMode"
      >
      </v-select>
      <template v-if="isMainPage()">
        <template v-if="ismobile">
          <v-btn class="mx-2" @click="dispConfigDialogOpen"> 条件 </v-btn>
        </template>
        <template v-else>
          <v-btn class="mx-5" @click="dispConfigDialogOpen"> 条件変更 </v-btn>
          {{ getDispConfigText() }}
        </template>
      </template>
      <v-spacer></v-spacer>
      <template v-if="isMainPage()">
        <v-btn v-if="canConvertData()" @click="convertData2()"><v-icon>mdi-axe-battle</v-icon></v-btn>
        <v-btn v-if="canConvertData()" @click="convertData()"><v-icon>mdi-mace</v-icon></v-btn>
        <vue-json-to-csv :labels="jsontocsv.labels" :json-data="jsontocsv.jsonData" :csv-title="jsontocsv.csvTitle">
          <template v-if="ismobile">
            <v-btn @click="doDounload"><v-icon>mdi-download</v-icon></v-btn>
          </template>
          <template v-else>
            <v-btn @click="doDounload">ダウンロード</v-btn>
          </template>
        </vue-json-to-csv>
      </template>
      <v-btn color="info" @click="startEdit" :small="!isMainPage() && !ismobile">追加</v-btn>
    </v-toolbar>
    <v-select
      prepend-icon="mdi-tree"
      label="申請区分"
      :items="this.getDispmodeList()"
      item-text="disp_name"
      item-value="id"
      v-model="dispmode"
      style="max-width: 300px"
      dense
      v-show="disp_cols === 12 && ismobile"
      class="mt-5 pl-4"
      @change="setDispMode"
    >
    </v-select>
    <v-row ref="card">
      <v-col cols="12" :md="disp_cols">
        <v-list dense v-show="disp_cols !== 12">
          <v-list-item-group v-model="dispmode" mandatory color="indigo">
            <v-list-item
              v-for="(item, index) in this.getDispmodeList()"
              :key="index"
              link
              @click="setDispMode(item.id)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols="12" :md="data_cols">
        <v-data-table
          ref="datatable"
          :headers="getHeaders()"
          :items="listitem"
          @click:row="showEvent"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100, 250, 500, -1],
            'items-per-page-text': '行/ページ:'
          }"
          :items-per-page.sync="config[getItemsPerPage()]"
          must-sort
          :sort-by.sync="dataTableSortBy"
          :sort-desc.sync="dataTableSortDesc"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.filename`]="{ item }"
            ><div>
              {{ item.filename }}
            </div>
          </template>
          <template v-slot:[`item.directry_id`]="{ item }">
            {{ getDirectryName(item.directry_id) }}
          </template>
          <template v-slot:[`item.template_id`]="{ item }">
            {{ getTemplateName(item.template_id) }}
          </template>
          <template v-slot:[`item.stateId`]="{ item }">
            <v-chip :color="getStateColor(item.stateId)" dark>
              {{ getStateText(item.stateId) }}
            </v-chip>
          </template>
          <template v-if="config.useTag" v-slot:[`item.tags`]="{ item }">
            <v-chip v-for="(tagItem, index) in item.tags" :key="index" :color="tagItem.back_color" dark>
              {{ tagItem.tag_name }}
            </v-chip>
          </template>
          <template v-slot:[`item.updatetime`]="{ item }">
            {{ getDateFormat(item.updatetime) }}
          </template>
          <template v-slot:[`item.inserttime`]="{ item }">
            {{ getDateFormat(item.inserttime) }}
          </template>
          <template v-slot:[`item.accept_time`]="{ item }">
            {{ getDateFormat(item.accept_time) }}
          </template>
          <template v-slot:[`item.insertuser`]="{ item }">
            {{ getUserName(item.insertuser) }}
          </template>
          <template v-slot:[`item.accept_user`]="{ item }">
            {{ getUserName(item.accept_user) }}
          </template>
          <template v-slot:[`item.filesize`]="{ item }">
            {{ fileSizeFormat(item.filesize) }}
          </template>
        </v-data-table>

        <custom-dialog v-model="selectedOpen">
          <template v-slot:title>ワークフロー</template>
          <template v-slot:body>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.extra">{{ isError.extraText }} </v-alert>
            <v-container>
              <v-row class="d-flex align-center py-0" no-gutters>
                <v-container d-flex justify-content-end mt-n4 mb-n8 pt-0>
                  <template v-if="config.useTag">
                    <v-tooltip bottom v-for="(tagItem, index) in selectedEvent.tags" :key="index">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          :color="tagItem.back_color"
                          @click:close="removeTag(selectedEvent, tagItem)"
                          v-bind="attrs"
                          v-on="on"
                          small
                          dark
                          close
                          close-icon="mdi-close"
                        >
                          {{ tagItem.tag_name }}
                        </v-chip>
                      </template>
                      <span>{{ getUserName(tagItem.updateuser) }}({{ getDateFormat(tagItem.updatetime) }})</span>
                    </v-tooltip>
                    <v-menu bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="#757575" small dark v-bind="attrs" v-on="on">
                          <v-icon small>mdi-plus</v-icon>
                        </v-chip>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(tagItem, index) in tagDefinitions"
                          :key="index"
                          @click="addTag(selectedEvent, tagItem)"
                          :color="tagItem.back_color"
                        >
                          {{ tagItem.disp_name }}</v-list-item
                        >
                      </v-list>
                    </v-menu>
                  </template>

                  <v-spacer />
                  <div class="pa-0 ma-0 overline">申請ID {{ getItemID(selectedEvent) }}</div>
                </v-container>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col cols="2">
                  <div class="pa-0 ma-0 text-subtitle-1">決裁状況</div>
                </v-col>
                <v-col cols="3">
                  <div class="pa-0 ma-0 text-subtitle-1" style="color: red">
                    {{ getStateText(selectedEvent.stateId) }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div class="pa-0 ma-0 text-subtitle-1">申請日</div>
                </v-col>
                <v-col cols="5">
                  <div class="pa-0 ma-0 text-subtitle-1">
                    {{ fullDateFormat(selectedEvent.inserttime) }}
                  </div>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">カテゴリ</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    item-text="disp_name"
                    item-value="id"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                    :items="workflowTemplate"
                    v-model="selectedEvent.template_id"
                    @change="category_change"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    hide-details="auto"
                    readonly
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">カテゴリ</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="config.useTitle">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">タイトル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.title"
                    :readonly="true"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">タイトル</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <dynamic-work-flow
                :rows="selectedEvent.dRows"
                :data="selectedEvent.dData"
                :readonly="true"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :ismobile="ismobile"
                :useIcon="getDispStyle(config.dispStyle).useIcon"
                :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
              />
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col cols="12">
                  <v-chip
                    v-for="(item, index) in selectedEvent.files"
                    :key="index"
                    @click="fileDownload(selectedEvent.filesData, item)"
                    >{{ getFileName(selectedEvent.filesData, item) }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col cols="12">
                  <v-card class="mx-1 mt-3" color="background_sub">
                    <v-container class="px-6 text-body-2" v-if="selectedEvent.route">
                      <v-row class="pa-0">
                        <v-col class="pa-0 text-subtitle-1">決裁履歴</v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col class="pa-0" cols="3">1.申請</v-col>
                        <v-col class="pa-0" cols="9">
                          <v-row no-gutters>
                            <template v-if="ismobile">
                              <v-col class="pa-0" cols="8">{{ getUserName(selectedEvent.insertuser) }}</v-col>
                              <v-col class="pa-0" cols="4"></v-col>
                              <v-col class="pa-0 text-right" cols="12">{{
                                fullDateFormat(selectedEvent.inserttime)
                              }}</v-col>
                            </template>
                            <template v-else>
                              <v-col class="pa-0" cols="4">{{ getUserName(selectedEvent.insertuser) }}</v-col>
                              <v-col class="pa-0" cols="3"></v-col>
                              <v-col class="pa-0 text-right" cols="5">{{
                                fullDateFormat(selectedEvent.inserttime)
                              }}</v-col>
                            </template>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row v-for="(item, index) in selectedEvent.mapRoutes" :key="index">
                        <v-col class="pa-0" cols="12"><v-divider /></v-col>
                        <template v-if="selectedEvent.current_row == item.row">
                          <v-col class="pa-0" cols="3">
                            {{ item.row + 1 }}.{{ item.disp_name }} {{ getRouteState(selectedEvent, index) }}
                          </v-col>
                          <v-col class="pa-0" cols="9">
                            <v-row v-for="(rItem, rIndex) in item.routeData" :key="rIndex" no-gutters>
                              <v-col class="pa-0" cols="12" v-if="rIndex > 0"><v-divider /></v-col>
                              <template v-if="canAccept(rItem)">
                                <v-col class="pa-0" cols="12">{{ getUserName(rItem.user_id) }}</v-col>
                                <v-col class="pa-0" cols="12">
                                  <v-textarea label="コメント" rows="3" v-model="rItem.message" />
                                </v-col>
                                <v-col class="pa-0 text-right" cols="12">
                                  <v-btn color="primary" @click="doAccept(rItem)">{{
                                    getAcceptBtnName(item)
                                  }}</v-btn></v-col
                                >
                                <v-col class="pa-0" cols="12">
                                  <v-row justify="end" align="center" no-gutters>
                                    <v-select
                                      style="max-width: 200px"
                                      prepend-icon="mdi-account-circle"
                                      label="差し戻し先"
                                      item-text="disp_name"
                                      item-value="row"
                                      :items="getRejectItems(selectedEvent, rItem.row)"
                                      v-model="rItem.reject_row"
                                    />
                                    に
                                    <v-btn color="warning" @click="doReject(rItem)">{{ getRejectBtnName(item) }}</v-btn>
                                  </v-row>
                                </v-col>
                              </template>
                              <template v-else>
                                <template v-if="ismobile">
                                  <v-col class="pa-0" cols="8">{{ getUserName(rItem.user_id) }}</v-col>
                                  <v-col class="pa-0" cols="4">
                                    <div :style="`color: ${getMapRouteStateColor(rItem)}`">
                                      {{ getMapRouteState(item, rItem) }}
                                    </div>
                                  </v-col>
                                  <v-col class="pa-0 text-right" cols="12">{{ getAcceptTime(rItem) }}</v-col>
                                </template>
                                <template v-else>
                                  <v-col class="pa-0" cols="4">{{ getUserName(rItem.user_id) }}</v-col>
                                  <v-col class="pa-0" cols="3">
                                    <div :style="`color: ${getMapRouteStateColor(rItem)}`">
                                      {{ getMapRouteState(item, rItem) }}
                                    </div>
                                  </v-col>
                                  <v-col class="pa-0 text-right" cols="5">{{ getAcceptTime(rItem) }}</v-col>
                                </template>
                                <v-col class="pa-0" cols="12">
                                  {{ rItem.message }}
                                </v-col>
                              </template>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col class="pa-0" cols="3">
                            {{ item.row + 1 }}.{{ item.disp_name }} {{ getRouteState(selectedEvent, index) }}
                          </v-col>
                          <v-col class="pa-0" cols="9">
                            <v-row v-for="(rItem, rIndex) in item.routeData" :key="rIndex" no-gutters>
                              <v-col class="pa-0" cols="12" v-if="rIndex > 0"><v-divider /></v-col>
                              <template v-if="ismobile">
                                <v-col class="pa-0" cols="8">{{ getUserName(rItem.user_id) }}</v-col>
                                <v-col class="pa-0" cols="4">
                                  <div :style="`color: ${getMapRouteStateColor(rItem)}`">
                                    {{ getMapRouteState(item, rItem) }}
                                  </div>
                                </v-col>
                                <v-col class="pa-0 text-right" cols="12">{{ getAcceptTime(rItem) }}</v-col>
                              </template>
                              <template v-else>
                                <v-col class="pa-0" cols="4">{{ getUserName(rItem.user_id) }}</v-col>
                                <v-col class="pa-0" cols="3">
                                  <div :style="`color: ${getMapRouteStateColor(rItem)}`">
                                    {{ getMapRouteState(item, rItem) }}
                                  </div>
                                </v-col>
                                <v-col class="pa-0 text-right" cols="5">{{ getAcceptTime(rItem) }}</v-col>
                              </template>
                              <v-col class="pa-0" cols="12">
                                {{ rItem.message }}
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-btn color="info" v-show="canReapply(selectedEvent)" @click="startEditing"> 再申請 </v-btn>
            <v-btn color="warning" v-show="canDeleteEvent(selectedEvent)" @click="deleteData"> 削除 </v-btn>
            <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
          </template>
        </custom-dialog>
        <custom-dialog v-model="editedOpen" :persistent="true">
          <template v-slot:title>依頼追加</template>
          <template v-slot:body>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.reapply"
              >すでに再申請されています。
            </v-alert>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.file"
              >『 ファイル 』を入力してください。
            </v-alert>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.category"
              >『 カテゴリ 』を選択してください。
            </v-alert>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.route"
              >『 申請経路 』を入力してください。
            </v-alert>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-for="(item, index) in isError.extraText" :key="index"
              >{{ item }}
            </v-alert>
            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">カテゴリ</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    item-text="disp_name"
                    item-value="id"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                    :items="workflowTemplate"
                    v-model="editedEvent.template_id"
                    @change="category_change"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">カテゴリ</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="config.useTitle">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">タイトル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.title"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">タイトル</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <dynamic-work-flow
                ref="dynamicWorkflow"
                :rows="editRows"
                :data="editData"
                :readonly="false"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :ismobile="ismobile"
                :useIcon="getDispStyle(config.dispStyle).useIcon"
                :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
              />
              <div v-show="useUserList">
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col :cols="12" class="pb-3">
                    <v-btn @click="useUserList = !useUserList">経路を編集する</v-btn>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                    <p class="mb-0">申請経路</p>
                  </v-col>
                  <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                    <v-select
                      item-text="route_name"
                      item-value="id"
                      :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                      style="max-width: 300px"
                      :items="workflowRoute"
                      v-model="editedEvent.route_id"
                      @change="route_change"
                      :outlined="getDispStyle(config.dispStyle).outlined"
                      :dense="dense"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <div v-if="getDispStyle(config.dispStyle).useTitleLabel">申請経路</div>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel"> </v-col>
                  <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                    <div>
                      <span>{{ getAcceptRouteText(editedEvent.mapRoutes) }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-show="!useUserList">
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col :cols="12">
                    <v-btn @click="useUserList = !useUserList">申請経路一覧から選択する</v-btn>
                  </v-col>
                </v-row>
                <route-setting
                  ref="route_setting"
                  v-model="editedEvent.mapRoutes"
                  :readonly="false"
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  :ismobile="ismobile"
                  :useIcon="getDispStyle(config.dispStyle).useIcon"
                  :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
                />
              </div>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">ファイル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <file-input
                    ref="fileinput"
                    :params="{
                      label: getDispStyle(config.dispStyle).useTitleLabel ? 'ファイル' : ''
                    }"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-paperclip' : ''"
                  />
                  <v-chip
                    v-for="(item, index) in editedEvent.files"
                    :key="index"
                    close
                    close-icon="mdi-close-outline"
                    @click:close="editedEvent.files = editedEvent.files.filter(n => n !== item)"
                    >{{ getFileName(editedEvent.filesData, item) }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-btn color="info" @click="endEdit"> 登録 </v-btn>
            <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
          </template>
        </custom-dialog>

        <custom-dialog v-model="dispCounfig.dialogOpen">
          <template v-slot:title>表示絞込み</template>
          <template v-slot:body>
            <v-alert class="ma-0" type="error" v-if="dispCounfig.error_message.length > 0" dense
              >{{ dispCounfig.error_message }}
            </v-alert>
            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">カテゴリ</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                      <p class="mb-0">カテゴリ</p>
                    </v-col>
                    <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                      <v-select
                        item-text="disp_name"
                        item-value="id"
                        multiple
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                        :items="workflowTemplate"
                        v-model="dispCounfig.template_id_edit"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">カテゴリ</div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-center py-1" no-gutters v-if="config.useTitle">
                    <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                      <p class="mb-0">タイトル</p>
                    </v-col>
                    <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                      <v-text-field
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.title_edit"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">タイトル</div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-center py-1" no-gutters>
                    <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                      <p class="mb-0">状況</p>
                    </v-col>
                    <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                      <v-select
                        item-text="disp_name"
                        item-value="id"
                        multiple
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-flag' : ''"
                        :items="workflowStateArray"
                        v-model="dispCounfig.state_id_edit"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">状況</div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex align-center py-0" no-gutters>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-calendar' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.start_date_edit"
                        :readonly="true"
                        hide-details="auto"
                        clearable
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">申請日（開始日）</div>
                        </template>
                        <template v-slot:append-outer>
                          <date-picker v-model="dispCounfig.start_date_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? '' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.end_date_edit"
                        :readonly="true"
                        hide-details="auto"
                        clearable
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">申請日（終了日）</div>
                        </template>
                        <template v-slot:append-outer>
                          <date-picker v-model="dispCounfig.end_date_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex align-center py-0 mt-n5" no-gutters>
                    <v-col cols="1"> </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.showdoing_edit"
                        label="未完了を表示する"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-center py-0" no-gutters>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-calendar' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.start_date2_edit"
                        :readonly="true"
                        hide-details="auto"
                        clearable
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">決裁日（開始日）</div>
                        </template>
                        <template v-slot:append-outer>
                          <date-picker v-model="dispCounfig.start_date2_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? '' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.end_date2_edit"
                        :readonly="true"
                        hide-details="auto"
                        clearable
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">決裁日（終了日）</div>
                        </template>
                        <template v-slot:append-outer>
                          <date-picker v-model="dispCounfig.end_date2_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-center py-1" no-gutters>
                    <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                      <p class="mb-0">申請者</p>
                    </v-col>
                    <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                      <v-select
                        item-text="disp_name"
                        item-value="id"
                        multiple
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                        :items="usersList.filter(x => x.enable)"
                        v-model="dispCounfig.user_id_edit"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">申請者</div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="config.useTag" class="d-flex align-center py-1" no-gutters>
                    <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                      <p class="mb-0">タグ</p>
                    </v-col>
                    <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                      <v-select
                        item-text="disp_name"
                        item-value="id"
                        multiple
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''"
                        :items="tagDefinitions"
                        v-model="dispCounfig.tag_id_edit"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">タグ</div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-btn color="accent" @click="dispConfigDialogClose()"> 閉じる </v-btn>
          </template>
        </custom-dialog>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import VueJsonToCsv from 'vue-json-to-csv';
import { mapState, mapActions } from 'vuex';
import DatePicker from '../components/DatePicker';
import FileInput from '../components/FileInput2.vue';
import CustomDialog from '../components/CustomDialog.vue';
import dateformat from '../functions/DateFormat';
import DynamicWorkFlow from '../components/DynamicWorkflow.vue';
// import MultiUserSelectList from '../components/MultiUserSelectList.vue';
import RouteSetting from '../components/WF3RouteSetting.vue';
import dateUtils from '../functions/DateUtils';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';
import scroll from '../../utils/scroll';

export default {
  components: {
    FileInput,
    DynamicWorkFlow,
    // MultiUserSelectList,
    RouteSetting,
    CustomDialog,
    DatePicker,
    VueJsonToCsv
  },
  data: () => ({
    name: 'workflow3',
    path: '/workflow3',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    dataTableSortBy: 'inserttime',
    dataTableSortDesc: true,
    dispmode: 0,
    workflowState: {
      apply: { id: 1, disp_name: '進行中', color: 'blue' },
      reject: { id: 2, disp_name: '却下', color: 'red' },
      accept: { id: 3, disp_name: '完了', color: 'green' }
    },
    workflowStateArray: [],
    listitem: [],
    fileindexlist: [],
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    dialogLeft: 0,
    disp_cols: 2,
    data_cols: 10,
    isError: {
      reapply: false,
      file: false,
      category: false,
      route: false,
      extra: false,
      extraText: []
    },
    selectedOpen: false,
    selectedFullScreen: false,
    selectedEvent: {},
    editedOpen: false,
    editedFullScreen: false,
    editedEvent: { route: [] },
    editRows: [],
    editData: [],
    useUserList: true,
    uploadCount: 0,
    uploadStartFile: [],
    uploadEndFile: [],
    editWfItemState: {
      delete: false,
      reapply: false
    },
    dispCounfig: {
      dialogOpen: false,
      error_message: '',
      start_date: '',
      end_date: '',
      start_date2: '',
      end_date2: '',
      template_id: [],
      state_id: [],
      title: '',
      user_id: [],
      tag_id: [],
      start_date_edit: '',
      end_date_edit: '',
      start_date2_edit: '',
      end_date2_edit: '',
      template_id_edit: [],
      title_edit: '',
      state_id_edit: [],
      user_id_edit: [],
      tag_id_edit: []
    },
    jsontocsv: {
      labels: {},
      jsonData: [],
      csvTitle: ''
    }
  }),
  mounted() {},
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    reLoading() {
      this.reload();
    },
    $route(to, from) {
      // ルートの変更の検知...
      console.log({ to, from });
      if (to.query.id) {
        this.dispWorkFlowItem(to.query.id);
      }
    }
  },
  // updated: function () {
  //   this.$nextTick(function () {
  //     this.doScrollTop();
  //   });
  // },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      reLoading: state => state.reLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      filedata: state => state.fileData.filedata,
      userConfig: state => state.userConfig.userconfig,
      workflowTemplate: state => state.workflowTemplate.workflowTemplate,
      workflowRoute: state => state.workflowRoute.workflowRoute,
      workflow3: state => state.workflow3.workflow3,
      tagDefinitions: state => state.tagDefinitions.tagDefinitions,
      systemsettings: state => state.systemsettings
    })
  },
  mixins: [token, fileOperation, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'fetchUserConfig',
      'upsertUserConfig',
      'fetchWorkflowTemplate',
      'fetchWorkflowRoute',
      'fetchWorkflow3',
      'fetchWorkflow3Item',
      'fetchWorkflow3State',
      'insertWorkflow3Apply',
      'insertWorkflow3Reapply',
      'insertWorkflow3Accept',
      'deleteWorkflow3',
      'workflowconverv2tov3',
      'workflowconverv3_1',
      'fetchWorkflowMap',
      'fetchWorkflowMapItem',
      'upsertWorkflowMaps',
      'fetchFileData',
      'downloadFile',
      'insertTagAssignment',
      'deleteTagAssignment'
    ]),
    init() {
      let p = [];
      p.push(this.fetchWorkflowTemplate());
      p.push(this.fetchWorkflowRoute());

      Promise.all(p).then(() => {
        let config = this.userConfig.find(item => item.user_id === this.getUserId());

        this.config[this.getItemsPerPage()] = config[this.getItemsPerPage()] || (this.isMainPage() ? 10 : 5);

        this.config.dispStyle = config.dispStyle || 1;
        // タイトル入力を行うか
        this.config.useTitle = this.systemsettings.workflow_title;
        // タグを扱うかどうか
        this.config.useTag = this.systemsettings.workflow_tag;

        this.dispCounfig.start_date = dateformat.format(dateUtils.addMonth(dateUtils.getToday(), -1), 'YYYY-MM-DD');
        this.dispCounfig.end_date = '';
        this.dispCounfig.showdoing = true;
        this.dispCounfig.start_date2 = '';
        this.dispCounfig.end_date2 = '';
        this.dispCounfig.template_id = [];
        this.dispCounfig.user_id = [];

        this.workflowStateArray = [this.workflowState.apply, this.workflowState.reject, this.workflowState.accept];

        this.dispWorkflowData();

        if (this.$route.query.id) {
          this.dispWorkflowData();
        }
      });
    },
    reload() {
      //データ取得
      this.dispWorkflowData();
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);

      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.selectedFullScreen = window.innerWidth < 600;
      this.editedFullScreen = window.innerWidth < 600;
      this.ismobile = document.getElementById('workflow3').clientWidth < 800;

      this.headers = this.getHeaders();
      if (this.isMainPage()) {
        this.disp_cols = 2;
        this.data_cols = 10;
      } else {
        this.disp_cols = 12;
        this.data_cols = 12;
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    getItemsPerPage() {
      return `${this.name}_items_per_page_${this.isMainPage() ? 'main' : 'sub'}`;
    },
    getDispmodeList() {
      const mePermission = this.getPermission(this.TOKEN_FEATURE.WORKFLOW, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.VIEW);
      // const otherPerMission = this.getPermission(
      //   this.TOKEN_FEATURE.WORKFLOW,
      //   this.TOKEN_OWNER.OTHER,
      //   this.TOKEN_ACTION.VIEW
      // );
      const otherPerMission = false;
      const ret = [];
      if (mePermission) {
        ret.push({ id: 0, disp_name: '受信した依頼' });
        ret.push({ id: 1, disp_name: '送信した依頼' });
      }
      if (otherPerMission || this.usersList.find(item => item.id === this.getUserId())?.admin) {
        //とりあえず管理者は全部見えるようにする
        ret.push({ id: 2, disp_name: '全ての依頼' });
      }
      return ret;
    },
    getHeaders() {
      const headers = [];
      const category = {
        text: 'カテゴリ',
        value: 'template_id'
      };
      const title = {
        text: 'タイトル',
        value: 'title'
      };
      const state = {
        text: '状況',
        value: 'stateId',
        align: 'center',
        width: '100px'
      };
      const tags = {
        text: 'タグ',
        value: 'tags',
        align: 'center'
      };
      const insertuser = {
        text: '申請者',
        value: 'insertuser',
        width: '120px'
      };
      const updateuser = {
        text: '決裁者',
        value: 'accept_user',
        width: '120px'
      };
      const inserttime = {
        text: '申請日',
        value: 'inserttime',
        align: 'end',
        width: '150px'
      };
      const acceptTime = {
        text: '決裁日',
        value: 'accept_time',
        align: 'end',
        width: '150px'
      };
      if (this.cardSize.x < 400) {
        headers.push(title);
        headers.push(state);
      } else if (this.cardSize.x < 600) {
        headers.push(title);
        headers.push(state);
        if (this.dispmode === 0) {
          headers.push(insertuser);
        } else {
          headers.push(updateuser);
        }
      } else {
        if (this.config.useTitle) {
          headers.push(category);
        }
        headers.push(title);
        headers.push(state);
        if (this.config.useTag) {
          headers.push(tags);
        }
        headers.push(insertuser);
        headers.push(updateuser);
        headers.push(inserttime);
        headers.push(acceptTime);
      }

      return headers;
    },
    canEditEvent(event) {
      let result = false;
      if (!event.insertuser) return false;

      const mePermission = this.getPermission(this.TOKEN_FEATURE.WORKFLOW, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.EDIT);
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.EDIT
      );
      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      return result;
    },
    canReapply(event) {
      //再申請可能か
      let result = false;
      if (!event.insertuser) return false;

      //作成者のみ
      result = event.insertuser === this.getUserId();

      return result && this.editWfItemState.reapply;
    },
    canDeleteEvent(event) {
      let result = false;
      if (!event.insertuser) return false;

      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.DELETE
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.DELETE
      );
      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }

      return result && this.editWfItemState.delete;
    },
    canAccept(mapItem) {
      let result = false;
      if (!mapItem.user_id) return false;

      const mePermission = this.getPermission(this.TOKEN_FEATURE.WORKFLOW, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.EDIT);
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.EDIT
      );
      if (mapItem.user_id === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      result = result && mapItem.accept == mapItem.reject;

      return result;
    },
    async showEvent(eventData) {
      // オブジェクトをコピー
      const wfItem = JSON.parse(JSON.stringify(eventData));

      wfItem.dRows = this.getTempRows(wfItem.template_id);
      wfItem.dData = this.getTempData(wfItem.dRows, wfItem.data);

      wfItem.mapRoutes = this.getMapRouteData(wfItem);

      // 表示対象のワークフローへのアクション情報をの取得
      const stateData = await this.fetchWorkflow3State(wfItem);
      this.editWfItemState.delete = stateData.data.delete;
      this.editWfItemState.reapply = stateData.data.reapply;

      this.selectedEvent = wfItem;
      this.selectedOpen = true;
    },
    /**
     * 承認実績データを作成する（未承認はダミーデータを挟む）
     */
    getMapRouteData(wfItem) {
      return wfItem.mapRoutes.map(x => ({
        ...x,
        routeData: x.route.map(y => ({
          ...(wfItem.maps.find(z => z.row == x.row && z.user_id == y) ?? {
            workflow_id: x.workflow_id,
            workflow_index: x.workflow_index,
            row: x.row,
            user_id: y,
            accept: false,
            reject: false,
            message: ''
          }),
          reject_row: -1
        }))
      }));
    },
    getRejectItems(fwItem, row) {
      const result = [];
      result.push({ id: -1, disp_name: '1.申請', row: -1 });
      result.push(
        ...fwItem.mapRoutes
          .filter(x => x.row < row && x.accept_count > 0)
          .map(x => ({ ...x, disp_name: `${x.row + 1}:${x.disp_name}` }))
      );
      return result;
    },
    fileDownload(filedata, id) {
      let item = filedata.find(item => item.id === id);
      if (!item) return '';
      this.downloadFile(item).then(res => {
        // 取得したファイルをダウンロードできるようにする
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', item.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

      // const data = this.selectedEvent;
      // this.updateWorkflow(data).then(() => {
      //   //表示するワークフローの一覧を取得
      //   this.dispWorkflowData();
      // });
    },
    startEdit() {
      const data = {
        id: -1,
        workflow_id: -1,
        workflow_index: 1,
        template_id: this.workflowTemplate[0].id,
        title: '',
        data: '[]',
        state: '進行中',
        files: [],
        route_id: -1,
        route: [],
        maps: [],
        mapRoutes: [],
        tags: [],
        filesData: [],
        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date()
      };

      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editRows = [];
      this.editData = [];

      // 申請初期選択可能な申請ルートがあるか
      this.useUserList = this.workflowRoute.length > 0;

      //ルート選択モードの場合承認経路の初期設定を行う
      if (this.useUserList) {
        let route = this.getDefRoute(this.editedEvent.template_id);
        if (route) {
          data.route_id = route.id;
          data.route = route.detail.flatMap(x => x.route);
          data.mapRoutes = JSON.parse(JSON.stringify(route.detail)).map(x => ({ ...x, expand: false }));
        }
      }
      3;

      this.editedOpen = true;
      this.editedEvent = data;
    },
    category_change() {
      let rows = this.getTempRows(this.editedEvent.template_id);
      let data = this.getTempData(rows, '[]');

      this.editRows = JSON.parse(JSON.stringify(rows));
      this.editData = JSON.parse(JSON.stringify(data));

      //ルート選択モードの場合承認経路の初期設定を行う
      if (this.useUserList) {
        let route = this.getDefRoute(this.editedEvent.template_id);
        if (route) {
          this.editedEvent.route_id = route.id;
          this.editedEvent.route = route.route.slice();
          this.editedEvent.mapRoutes = JSON.parse(JSON.stringify(route.detail)).map(x => ({
            ...x,
            expand: false
          }));
        }
      }
    },
    getTempRows(template_id) {
      return this.workflowTemplate.find(x => x.id == template_id).rows;
    },
    getTempData(rows, dataString) {
      let data = null;
      try {
        data = JSON.parse(dataString);
        if (!Array.isArray(data)) {
          data = [];
        }
      } catch (error) {
        //
        console.log(error);
      }
      return rows.map(x => {
        const result = data.find(y => x.id == y.id) ?? { id: x.id, value: null, value2: null };
        const def = this.createDefDataItem(x);
        result.value = result.value ?? def.value;
        result.value2 = result.value2 ?? def.value2;
        return result;
      });
    },
    createAcceptData(wfItem) {
      let data = { message: '', route_users: [], user_id: -1 };

      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
      //申請者
      let insUser = JSON.parse(JSON.stringify(this.usersList.find(item => item.id === wfItem.insertuser)));
      insUser.disp_name = '申請者';
      insUser.id = -1;
      data.route_users.push(insUser);
      findmaps.forEach(map => {
        if (!map.reject) {
          data.route_users.push(this.usersList.find(item => item.id === map.user_id));
        }
      });
      data.user_id = data.route_users[0].id;

      let rejectMap = this.getRejectMap(this.selectedEvent);
      if (rejectMap) {
        data.message = rejectMap.message;
      }

      return data;
    },
    createDefDataItem(row) {
      let today = dateformat.format(dateUtils.getToday(), 'YYYY-MM-DD');
      let nowTime = dateformat.format(dateUtils.getNow(), 'HH:mm');
      let tomorrow = dateformat.format(dateUtils.getTomorrow(), 'YYYY-MM-DD');
      let yesterday = dateformat.format(dateUtils.getYesterday(), 'YYYY-MM-DD');
      let lastSunday = dateformat.format(dateUtils.getLastSundy(), 'YYYY-MM-DD');
      let lastWeekMonday = dateformat.format(dateUtils.getLastWeekMonday(), 'YYYY-MM-DD');
      let nextSaturday = dateformat.format(dateUtils.getNextSaturday(), 'YYYY-MM-DD');
      let nextMonday = dateformat.format(dateUtils.getNextMonday(), 'YYYY-MM-DD'); //日付は特別実装
      if (row.type === 'date_span') {
        if (row.default_value === 'last_week') {
          return {
            value: lastWeekMonday,
            value2: lastSunday
          };
        } else if (row.default_value === 'tomorrow') {
          return {
            value: tomorrow,
            value2: tomorrow
          };
        } else if (row.default_value === 'yesterday') {
          return {
            value: yesterday,
            value2: yesterday
          };
        } else if (row.default_value === 'next_saturday') {
          return {
            value: nextSaturday,
            value2: nextSaturday
          };
        } else if (row.default_value === 'next_monday') {
          return {
            value: nextMonday,
            value2: nextMonday
          };
        } else if (row.default_value === 'today') {
          return {
            value: today,
            value2: today
          };
        } else {
          return {
            value: today,
            value2: today
          };
        }
      } else if (row.type === 'DatePicker') {
        if (row.default_value === 'last_week') {
          return {
            value: lastWeekMonday,
            value2: false
          };
        } else if (row.default_value === 'tomorrow') {
          return {
            value: tomorrow,
            value2: false
          };
        } else if (row.default_value === 'yesterday') {
          return {
            value: yesterday,
            value2: false
          };
        } else if (row.default_value === 'next_saturday') {
          return {
            value: nextSaturday,
            value2: false
          };
        } else if (row.default_value === 'next_monday') {
          return {
            value: nextMonday,
            value2: false
          };
        } else if (row.default_value === 'today') {
          return {
            value: today,
            value2: false
          };
        } else {
          return {
            value: today,
            value2: false
          };
        }
      } else if (row.type === 'TimePicker') {
        if (row.default_value === 'now') {
          return {
            value: nowTime,
            value2: nowTime
          };
        } else if (row.default_value !== '') {
          return {
            value: row.default_value,
            value2: row.default_value
          };
        } else {
          return {
            value: nowTime,
            value2: nowTime
          };
        }
      } else if (row.type === 'date_month') {
        return {
          value: dateUtils.getYear(dateUtils.getToday()),
          value2: dateUtils.getMonth(dateUtils.getToday())
        };
      } else if (row.type === 'VSelect') {
        //スピナーは数値で処理する必要がある
        return { value: parseInt(row.default_value), value2: '' };
      } else {
        return { value: row.default_value, value2: '' };
      }
    },
    route_change() {
      console.log([this.editedEvent.route_id]);
      let selectRoute = this.workflowRoute.find(item => item.id === this.editedEvent.route_id);
      if (selectRoute) {
        this.editedEvent.route = selectRoute.route.slice();
        this.editedEvent.mapRoutes = JSON.parse(JSON.stringify(selectRoute.detail)).map(x => ({ ...x, expand: false }));
      }
    },
    getDefRoute(category_id) {
      const user_id = this.getUserId();
      const group_id = this.usergroup.filter(x => x.user_id.find(x => x === user_id)).map(x => x.id);

      //defaultの承認経路の取得
      let route = null;
      if (category_id > 0) {
        //カテゴリとユーザーが一致するもの
        route = this.workflowRoute.find(
          x => x.def_category_id.find(x => x === category_id) && x.def_user_id.find(x => x === user_id)
        );
        if (!route) {
          //カテゴリとグループが一致するもの
          route = this.workflowRoute.find(
            x => x.def_category_id.find(x => x === category_id) && x.def_group_id.find(x => group_id.find(y => y === x))
          );
        }
      }
      if (!route) {
        //カテゴリ未設定でユーザーが一致するもの
        route = this.workflowRoute.find(
          x => (!x.def_category_id || x.def_category_id.length === 0) && x.def_user_id.find(x => x === user_id)
        );
      }
      if (!route) {
        //カテゴリ未設定でグループが一致するもの
        route = this.workflowRoute.find(
          x =>
            (!x.def_category_id || x.def_category_id.length === 0) &&
            x.def_group_id.find(x => group_id.find(y => y === x))
        );
      }
      return route;
    },
    startEditing() {
      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }

      this.editedEvent = JSON.parse(JSON.stringify(this.selectedEvent));
      this.editedEvent.workflow_index += 1;
      this.editedEvent.id = -1; //idを初期か

      this.editRows = this.getTempRows(this.editedEvent.template_id);
      this.editData = this.getTempData(this.editRows, this.editedEvent.data);

      //申請経路が選択済みのものなら申請経路選択とする
      let selectRoute = this.workflowRoute.find(item => item.id === this.editedEvent.route_id);
      if (selectRoute) {
        this.useUserList = JSON.stringify(this.editedEvent.route) === JSON.stringify(selectRoute.route);
      } else {
        this.useUserList = false;
      }
      this.selectedOpen = false;
      this.editedOpen = true;
    },
    async deleteData() {
      //削除確認
      if (!window.confirm(`この依頼を削除してよろしいですか？`)) return;

      // workflowデータおよびworkflowmapデータを付随するファイルデータとともに削除
      const { data } = await this.deleteWorkflow3({ data: this.selectedEvent });

      if (data.message != '') {
        //登録エラーあり
        this.isError.extra = true;
        this.isError.extraText = data.message;
      } else {
        //表示するスケジュールの一覧を取得
        this.dispWorkflowData();
        this.selectedOpen = false;
        this.editedOpen = false;
      }
    },
    errrorReset() {
      for (var key in this.isError) {
        this.isError[key] = false;
      }
    },
    inputCheck() {
      const extraErrors = this.$refs.dynamicWorkflow.inputCheck();
      this.isError.extraText = [];
      if (extraErrors.length > 0) {
        this.isError.extra = true;
        this.isError.extraText.push(...extraErrors);
      }
      const extraErros2 = this.$refs.route_setting.inputCheck();
      if (extraErros2.length > 0) {
        this.isError.extra = true;
        this.isError.extraText.push(...extraErros2);
      }

      //route_setting
      //カテゴリ選択チェック
      this.isError.category = this.editedEvent.template_id === 1;

      //申請経路チェック
      this.isError.route = !this.editedEvent.mapRoutes.some(x => x.route.length > 0);

      // let files = this.$refs.fileinput.files;
      // //ファイル数チェック
      // this.isError.file = files.length <= 0;

      for (var key in this.isError) {
        if (this.isError[key] && typeof this.isError[key] == 'boolean') {
          this.doScrollTop();
          return false;
        }
      }
      return true;
    },
    dispConfigDialogOpen() {
      this.dispCounfig.title_edit = this.dispCounfig.title;
      this.dispCounfig.state_id_edit = [...this.dispCounfig.state_id];
      this.dispCounfig.start_date_edit = this.dispCounfig.start_date;
      this.dispCounfig.end_date_edit = this.dispCounfig.end_date;
      this.dispCounfig.showdoing_edit = this.dispCounfig.showdoing;
      this.dispCounfig.start_date2_edit = this.dispCounfig.start_date2;
      this.dispCounfig.end_date2_edit = this.dispCounfig.end_date2;
      this.dispCounfig.template_id_edit = [...this.dispCounfig.template_id];
      this.dispCounfig.user_id_edit = [...this.dispCounfig.user_id];
      this.dispCounfig.tag_id_edit = [...this.dispCounfig.tag_id];
      this.dispCounfig.dialogOpen = true;
    },
    dispConfigDialogClose() {
      this.dispCounfig.error_message = '';

      if (this.dispCounfig.start_date_edit?.length > 0 && this.dispCounfig.end_date_edit?.length > 0) {
        if (
          new Date(dateUtils.getDate(this.dispCounfig.start_date_edit)) >
          new Date(dateUtils.getDate(this.dispCounfig.end_date_edit))
        ) {
          this.dispCounfig.error_message = '申請日『 終了日 』は『 開始日 』以降の日付を指定してください。';
          return;
        }
      }
      if (this.dispCounfig.start_date2_edit?.length > 0 && this.dispCounfig.end_date2_edit?.length > 0) {
        if (
          new Date(dateUtils.getDate(this.dispCounfig.start_date2_edit)) >
          new Date(dateUtils.getDate(this.dispCounfig.end_date2_edit))
        ) {
          this.dispCounfig.error_message = '決裁日『 終了日 』は『 開始日 』以降の日付を指定してください。';
          return;
        }
      }

      this.dispCounfig.dialogOpen = false;
      this.dispCounfig.title = this.dispCounfig.title_edit;
      this.dispCounfig.state_id = [...this.dispCounfig.state_id_edit];
      this.dispCounfig.start_date = this.dispCounfig.start_date_edit;
      this.dispCounfig.end_date = this.dispCounfig.end_date_edit;
      this.dispCounfig.showdoing = this.dispCounfig.showdoing_edit;
      this.dispCounfig.start_date2 = this.dispCounfig.start_date2_edit;
      this.dispCounfig.end_date2 = this.dispCounfig.end_date2_edit;
      this.dispCounfig.template_id = [...this.dispCounfig.template_id_edit];
      this.dispCounfig.user_id = [...this.dispCounfig.user_id_edit];
      this.dispCounfig.tag_id = [...this.dispCounfig.tag_id_edit];
      this.dispWorkflowData();
    },
    async endEdit() {
      this.errrorReset();
      //入力チェック
      if (!this.inputCheck()) return;

      //登録処理を呼び出す
      const { data } = await this.registData();

      if (data.message != '') {
        //登録エラーあり
        this.isError.extra = true;
        this.isError.extraText = data.message;
      } else {
        //表示するスケジュールの一覧を取得
        this.dispWorkflowData();
        this.selectedOpen = false;
        this.editedOpen = false;
      }
    },
    async registData() {
      let files = this.$refs.fileinput.files;

      let formData = new FormData();
      if (files.length > 0) {
        files.forEach(file => {
          formData.append('files', file);
        });
      }
      let workFlowTitle = this.editedEvent.title;
      if (workFlowTitle == '') {
        workFlowTitle = this.workflowTemplate.find(item => item.id === this.editedEvent.template_id).disp_name;
      }

      const data = {
        template_id: this.editedEvent.template_id,
        workflow_id: this.editedEvent.workflow_id,
        workflow_index: this.editedEvent.workflow_index,
        title: workFlowTitle,
        data: JSON.stringify(this.editData),
        state: '',
        files: Array.from(this.editedEvent.files),
        route_id: this.editedEvent.route_id,
        route: this.editedEvent.route,
        mapRoutes: this.editedEvent.mapRoutes
      };

      formData.append('data', JSON.stringify(data));

      if (this.editedEvent.workflow_id < 0) {
        //新規ワークフロー（新規申請）
        return await this.insertWorkflow3Apply(formData);
      } else {
        //新規ワークフロー（再申請）
        return await this.insertWorkflow3Reapply(formData);
      }
    },
    cancelEdit() {
      this.$refs.fileinput.fileClear();
      this.selectedOpen = false;
      this.editedOpen = false;
    },
    // 差戻
    async doReject(mapItem) {
      const data = {
        workflow_id: mapItem.workflow_id,
        workflow_index: mapItem.workflow_index,
        row: mapItem.row,
        user_id: mapItem.user_id,
        accept: false,
        reject: true,
        message: mapItem.message,
        reject_row: mapItem.reject_row
      };

      const result = await this.insertWorkflow3Accept(data);

      if (result.data.message != '') {
        //登録エラーあり
        this.isError.extra = true;
        this.isError.extraText = result.data.message;
      } else {
        //表示するスケジュールの一覧を取得
        this.dispWorkflowData();
        this.selectedOpen = false;
        this.editedOpen = false;
      }
    },
    //承認
    async doAccept(mapItem) {
      const data = {
        workflow_id: mapItem.workflow_id,
        workflow_index: mapItem.workflow_index,
        row: mapItem.row,
        user_id: mapItem.user_id,
        accept: true,
        reject: false,
        message: mapItem.message,
        reject_row: -1
      };

      const result = await this.insertWorkflow3Accept(data);

      if (result.data.message != '') {
        //登録エラーあり
        this.isError.extra = true;
        this.isError.extraText = result.data.message;
      } else {
        //表示するスケジュールの一覧を取得
        this.dispWorkflowData();
        this.selectedOpen = false;
        this.editedOpen = false;
      }
    },
    dispWorkflowData() {
      this.setDispMode(this.dispmode);
    },
    async setDispMode(dispmode) {
      const data = {
        dispmode,
        start_date: this.dispCounfig.start_date ?? '',
        end_date: this.dispCounfig.end_date ?? '',
        showdoing: this.dispCounfig.showdoing ?? false,
        start_date2: this.dispCounfig.start_date2 ?? '',
        end_date2: this.dispCounfig.end_date2 ?? '',
        template_id: this.dispCounfig.template_id,
        user_id: this.dispCounfig.user_id,
        tag_id: this.dispCounfig.tag_id,
        state_id: this.dispCounfig.state_id,
        title: this.dispCounfig.title
      };
      if (data.start_date.length > 0) data.start_date += 'T00:00:00+09:00';
      if (data.end_date.length > 0) data.end_date += 'T00:00:00+09:00';
      if (data.start_date2.length > 0) data.start_date2 += 'T00:00:00+09:00';
      if (data.end_date2.length > 0) data.end_date2 += 'T00:00:00+09:00';

      const result = await this.fetchWorkflow3(data);
      const wfData = this.workflow3;
      this.listitem = wfData.workflow;
      console.log(result);
      //検索設定の保存
      this.saveConfig();
    },
    isDispWorkflow(wfItem, dispmode) {
      const mePermission = this.getPermission(this.TOKEN_FEATURE.WORKFLOW, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.VIEW);
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.VIEW
      );

      //ワークフローを表示するか
      if (dispmode === 0) {
        //受信した依頼
        //承認者が自分
        if (this.getNextAuthUser(wfItem) === this.getUserId()) {
          return mePermission;
        }
        //申請経路を取得
        let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
        //承認済みの経路に自分がいる
        const exist = findmaps.find(map => map.user_id === this.getUserId() && !map.reject);
        if (exist) {
          return mePermission;
        }
      } else if (dispmode === 1) {
        //送信した依頼

        //申請者が自分
        if (wfItem.insertuser === this.getUserId()) {
          return mePermission;
        }
      } else {
        //全ての依頼
        return otherPerMission || this.usersList.find(item => item.id === this.getUserId())?.admin;
      }
      return false;
    },
    async dispWorkFlowItem(id) {
      try {
        await this.$router.push(this.name);
        const workflowResult = await this.fetchWorkflow3Item({ id: id });
        const wfItem = workflowResult.data;

        if (this.filterWorkflowMaps(this.workflowMap, wfItem).length <= 0) {
          const workFlowMap = await this.fetchWorkflowMapItem({
            workflow_id: wfItem.workflow_id,
            workflow_index: wfItem.workflow_index
          });
          this.workflowMap.push(...workFlowMap.data);
        }

        this.showEvent(wfItem);
      } catch (err) {
        console.log(err);
      }
    },
    filterWorkflowMaps(workflowMap, wfItem) {
      return wfItem.maps ?? [];
      // return workflowMap.filter(
      //   mpItem =>
      //     mpItem.workflow_id === wfItem.workflow_id &&
      //     mpItem.workflow_index === wfItem.workflow_index
      // );
    },

    saveConfig() {
      //選択状態を保存する
      let promise = this.fetchUserConfig();

      //現状保存するものがない
      // promise = promise
      //   .then(() => {
      //     let config = this.userConfig.find(
      //       item => item.user_id === this.getUserId()
      //     );

      //     if (!config) {
      //       config = {};
      //       config.user_id = this.getUserId();
      //     }
      //     return this.upsertUserConfig(config);
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
      promise.catch(err => {
        console.log(err);
      });
      return promise;
    },
    getDownloadLink(filedata, id) {
      let item = filedata.find(item => item.id === id);
      if (!item) return '';
      return process.env.VUE_APP_API_URL_FILE + '/download/' + item.filename;
    },

    getFileName(filedata, id) {
      if (!id) return '---';
      let item = filedata.find(item => item.id === id);
      if (!item) return '---';
      return item.originalname + '(' + this.fileSizeFormat(item.filesize) + ')';
    },
    getDirectryName(id) {
      if (!id) return '---';
      let item = this.directories.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getTemplateName(id) {
      if (!id) return '---';
      let item = this.workflowTemplate.find(x => x.id == id);
      if (!item) return '---';
      return item.disp_name;
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      if (!date) return '---';
      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      if (!date) return '---';
      return dateformat.fullFormat(date);
    },
    getAcceptMap(wfItem, user_id) {
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);

      let map = findmaps.find(map => map.user_id === user_id);
      return map;
    },
    getAcceptMessage(wfItem, user_id) {
      let map = this.getAcceptMap(wfItem, user_id);
      if (map) {
        return map.message;
      } else {
        return '';
      }
    },
    getRouteState(wfItem, index) {
      const mapRoute = wfItem.mapRoutes[index];

      if (wfItem.stateId == this.workflowState.apply.id) {
        // 進行中の場合、進行中の行をもとに表示する
        if (wfItem.current_row == mapRoute.row) {
          // return '中';
          return `(${mapRoute.routeData.filter(x => x.accept && !x.reject).length}/${mapRoute.accept_count})`;
        } else if (wfItem.current_row > mapRoute.row) {
          return '済';
        }
        return '';
      }

      // 完了・否決の場合、否決があるかを確認する
      const rejectIndex =
        wfItem.mapRoutes
          .map((x, i) => ({ index: i, reject: x.routeData.some(y => y.reject && !y.accept) }))
          .find(x => x.reject)?.index ?? -1;
      if (mapRoute.routeData.some(x => x.reject && !x.accept)) {
        return '否';
      } else {
        if (rejectIndex == -1 || rejectIndex > index) {
          return '済';
        }
        return '';
      }
    },
    getAcceptTime(mapItem) {
      if (mapItem.accepttime) {
        return this.fullDateFormat(mapItem.accepttime);
      } else {
        return '';
      }
    },
    getMapRouteState(routeItem, mapItem) {
      if (mapItem.accept && !mapItem.reject) {
        return routeItem?.accept_name?.length > 0 ? routeItem?.accept_name : routeItem.disp_name;
      } else if (!mapItem.accept && mapItem.reject) {
        return routeItem?.reject_name?.length > 0 ? routeItem?.reject_name : '否決';
      } else {
        return '';
      }
    },
    getMapRouteStateColor(mapItem) {
      if (mapItem.accept && !mapItem.reject) {
        return 'black';
      } else if (!mapItem.accept && mapItem.reject) {
        return 'red';
      } else {
        return 'black';
      }
    },
    getAcceptBtnName(routeItem) {
      return (routeItem?.accept_name?.length > 0 ? routeItem?.accept_name : routeItem.disp_name) + 'する';
    },
    getRejectBtnName() {
      return '差し戻し';
    },
    getAcceptDateTime(wfItem, user_id) {
      let map = this.getAcceptMap(wfItem, user_id);
      if (map) {
        return new Date(map.updatetime);
      } else {
        return null;
      }
    },
    getStateColor(stateId) {
      return this.getWfState(stateId)?.color ?? '';
    },
    getStateText(stateId) {
      return this.getWfState(stateId)?.disp_name ?? '---';
    },
    getWfState(stateId) {
      return [this.workflowState.apply, this.workflowState.accept, this.workflowState.reject].find(
        x => x.id == stateId
      );
    },
    getAcceptRouteText(mapRoutes) {
      return (
        mapRoutes
          .map(x => {
            const userNames = x.route.map(y => this.usersList.find(z => z.id == y)?.disp_name ?? '---');
            const userName = userNames.length == 1 ? userNames[0] : userNames.join(',');

            if (x.accept_count == 0) {
              return userName + '(自動)';
            } else if (userNames.length == 1 && x.accept_count == 1) {
              return userName;
            } else {
              return userName + '(' + x.accept_count + '人)';
            }
          })
          .join('->') + '->完了'
      );
    },
    //最終決裁者取得（表示用）
    getDispAuthUser(wfItem) {
      let route = JSON.parse(JSON.stringify(wfItem.route));
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);

      //決裁情報がない
      if (findmaps.length === 0) {
        //最初のユーザー
        return route[0];
      }

      //最終決裁状況が否決
      let lastMap = findmaps[findmaps.length - 1];
      if (!lastMap.accept && !lastMap.reject) {
        //ユーザーなし
        return -1;
      }

      for (let i = 0; i < route.length; i++) {
        //承認状態の取得
        let map = findmaps.find(map => map.user_id === route[i]);
        if (!map) {
          //mapが存在しない場合はそのユーザーが次の承認者
          return route[i];
        }
        if (map.reject) {
          //reject指定されている場合はそのユーザー
          return route[i];
        }
        if (i === route.length - 1) {
          if (map.accept) {
            //決裁されている場合は決裁ユーザー
            return route[i];
          }
        }
      }
      //いずれも一致しない場合は拒否
      return -1;
    },
    //決裁者取得
    getNextAuthUser(wfItem) {
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
      //未承認の場合最初のユーザー
      if (findmaps.length === 0) {
        return wfItem.route[0];
      }
      //最後の要素が申請者への差し戻しの場合、次の承認者なし
      let lastMap = findmaps[findmaps.length - 1];
      if (!lastMap.accept && !lastMap.reject) return -1;

      //コピーする
      let route = JSON.parse(JSON.stringify(wfItem.route));

      //承認済ではない承認者を探す
      for (let i = 0; i < route.length; i++) {
        if (findmaps.find(map => map.user_id === route[i] && !map.reject)) {
          route[i] = -1;
        }
      }
      for (let i = 0; i < route.length; i++) {
        if (route[i] > 0) {
          return route[i]; //次の決裁者を返す
        }
      }
      return -1; //全て承認済
    },
    //再決裁時の決裁者初期メッセージ
    getRejectMap(wfItem) {
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
      //未承認の場合undefined
      if (findmaps.length === 0) {
        return undefined;
      }
      //コピーする
      let route = JSON.parse(JSON.stringify(wfItem.route));

      //承認済ではない承認者を探す
      for (let i = 0; i < route.length; i++) {
        let map = findmaps.find(map => map.user_id === route[i]);
        if (map) {
          //否決されたものを返す
          if (map.reject) {
            return map;
          }
        }
      }
      return undefined; //全て承認済
    },
    isNextAuthUser(wfItem, user_id) {
      return this.getNextAuthUser(wfItem) === user_id;
    },
    async removeTag(wfItem, tagItem) {
      //削除確認
      if (!window.confirm(`このタグを削除してよろしいですか？`)) return;

      // タグの削除
      const data = tagItem;
      await this.deleteTagAssignment(data);

      //表示データの再取得
      const workflowResult = await this.fetchWorkflow3Item({
        id: wfItem.id
      });
      //tagを同期
      this.selectedEvent.tags = workflowResult.data.tags;
      this.listitem.find(x => x.id == this.selectedEvent.id).tags = this.selectedEvent.tags;
    },
    async addTag(wfItem, tagItem) {
      //タグの追加
      const data = {
        feature_id: 0,
        feature_name: 'workflow3',
        record_id: wfItem.id,
        sort: (wfItem.tags?.length ?? 0) + 1,
        tag_id: tagItem.id,
        tag_name: tagItem.disp_name,
        fore_color: tagItem.fore_color,
        back_color: tagItem.back_color
      };
      await this.insertTagAssignment(data);
      //表示データの再取得
      const workflowResult = await this.fetchWorkflow3Item({
        id: wfItem.id
      });
      //tagを同期
      this.selectedEvent.tags = workflowResult.data.tags;
      this.listitem.find(x => x.id == this.selectedEvent.id).tags = this.selectedEvent.tags;
    },

    getDispConfigText() {
      const result = [];
      if (this.dispCounfig.template_id.length > 0) {
        result.push(
          ' カテゴリ：' +
            this.dispCounfig.template_id.map(x => this.workflowTemplate.find(y => y.id == x).disp_name).join()
        );
      }
      if (this.dispCounfig.title != '') {
        result.push(' タイトル：' + this.dispCounfig.title);
      }
      if (this.dispCounfig.state_id.length > 0) {
        result.push(' 状況：' + this.dispCounfig.state_id.map(x => this.getStateText(x)).join());
      }

      if ((this.dispCounfig.start_date || '') != '' || (this.dispCounfig.end_date || '') != '') {
        result.push('申請日『');
        result.push((this.dispCounfig.start_date || '') == '' ? '  ' : this.dispCounfig.start_date);
        result.push('～');
        result.push((this.dispCounfig.end_date || '') == '' ? '  ' : this.dispCounfig.end_date);
        if (this.dispCounfig.showdoing) {
          result.push('(未完了を除く)');
        }
        result.push('』');
      }
      if ((this.dispCounfig.start_date2 || '') != '' || (this.dispCounfig.end_date2 || '') != '') {
        result.push('決裁日『');
        result.push((this.dispCounfig.start_date2 || '') == '' ? '  ' : this.dispCounfig.start_date2);
        result.push('～');
        result.push((this.dispCounfig.end_date2 || '') == '' ? '  ' : this.dispCounfig.end_date2);
        result.push('』');
      }
      if (this.dispCounfig.user_id.length > 0) {
        result.push(
          ' 申請者：' + this.dispCounfig.user_id.map(x => this.usersList.find(y => y.id == x).disp_name).join()
        );
      }
      if (this.dispCounfig.tag_id.length > 0) {
        result.push(
          ' タグ：' + this.dispCounfig.tag_id.map(x => this.tagDefinitions.find(y => y.id == x).disp_name).join()
        );
      }
      if (result.length == 0) {
        result.push('全て');
      }

      return result.join(' ');
    },
    getItemID(item) {
      return `W${('000' + item.template_id).slice(-3)}-${('00000000' + item.workflow_id).slice(-6)}-${(
        '00' + item.workflow_index
      ).slice(-2)}`;
    },
    doDounload() {
      if (this.listitem.length == 0) {
        alert('対象データが0件です。');
        return;
      }

      //申請経路の最長のものを探す
      const maxRouteCount = Math.max(...this.listitem.map(x => x.mapRoutes.flatMap(y => y.route).length));
      console.log(maxRouteCount);

      const jsontocsv = this.jsontocsv;

      const labels = {};
      labels.workflowId = { title: '申請ID' };
      if (this.config.useTitle) {
        labels.category = { title: 'カテゴリ' };
      }
      labels.title = { title: 'タイトル' };
      labels.state = { title: '状況' };
      labels.user_name = { title: '申請者' };
      labels.user_name2 = { title: '決裁者' };
      labels.insert_time = { title: '申請日' };
      labels.update_time = { title: '決裁日' };

      for (var i = 0; i < maxRouteCount; i++) {
        labels['accept_type' + (i + 1)] = { title: '承認状況' + (i + 1) };
        labels['accept_user' + (i + 1)] = { title: '承認者' + (i + 1) };
        labels['accept_date' + (i + 1)] = { title: '承認日' + (i + 1) };
      }

      jsontocsv.labels = labels;

      // const sortBy = this.dataTableSortBy;
      // const sortDesc = this.dataTableSortDesc;
      const sortBy = 'inserttime';
      const sortDesc = true;

      const data = this.listitem
        .sort((a, b) => {
          return new Date(a[sortBy]) < new Date(b[sortBy]) == sortDesc ? 1 : -1;
        })
        .map(x => {
          const data = {};
          data.workflowId = this.getItemID(x);
          if (this.config.useTitle) {
            data.category = this.getTemplateName(x.template_id);
          }
          data.title = x.title;
          data.state = this.getStateText(x.stateId);
          data.user_name = this.getUserName(x.insertuser);
          data.user_name2 = this.getUserName(x.accept_user);
          data.insert_time = dateformat.format(x.inserttime, 'YYYY-MM-DD HH:mm');
          //決裁日
          if (x.accept_time) {
            data.update_time = dateformat.format(x.accept_time, 'YYYY-MM-DD HH:mm');
          } else {
            data.update_time = '---';
          }

          for (var i = 0; i < maxRouteCount; i++) {
            data['accept_type' + (i + 1)] = '';
            data['accept_user' + (i + 1)] = '';
            data['accept_date' + (i + 1)] = '';
          }

          const mapRoutes = this.getMapRouteData(x);
          const routelist = mapRoutes.flatMap(y => {
            return y.routeData.map(z => {
              const result = { title: '', user_name: this.getUserName(z.user_id), insert_time: '' };
              if (z.accept !== z.reject) {
                result.title = this.getMapRouteState(z);
                result.insert_time = dateformat.format(z.accepttime, 'YYYY-MM-DD HH:mm');
              }
              if (y.accept_count == 0) {
                result.title = '自動';
                result.insert_time = '---';
              }

              return result;
            });
          });

          for (let i = 0; i < maxRouteCount; i++) {
            if (routelist.length > i) {
              data['accept_user' + (i + 1)] = routelist[i].user_name;
            }
          }

          for (let i = 0; i < maxRouteCount; i++) {
            if (routelist.length > i) {
              data['accept_type' + (i + 1)] = routelist[i].title;
              data['accept_date' + (i + 1)] = routelist[i].insert_time;
              if (routelist[i].title == '否認') {
                break;
              }
              if (data.state == '進行中' && routelist[i].insert_time == '') {
                break;
              }
            }
          }

          return data;
        });
      jsontocsv.jsonData = data;

      // jsontocsv.csvTitle =
      //   (this.getDispmodeList().find(x => x.id == this.dispmode)?.disp_name ||
      //     '') + this.getDispConfigText();
      jsontocsv.csvTitle = 'workflow_data';
    },
    isDevMode() {
      return location.hostname === 'localhost';
    },
    canConvertData() {
      //開発者向け内部モード
      const password = localStorage.getItem('dev-mode') ?? '';
      return this.usersList.find(item => item.id === this.getUserId())?.admin && password != '';
    },
    async convertData() {
      const password = localStorage.getItem('dev-mode') ?? '';

      const result = await this.workflowconverv2tov3({ password });

      alert(result.data.message);
    },
    async convertData2() {
      const password = localStorage.getItem('dev-mode') ?? '';

      const result = await this.workflowconverv3_1({ password });

      alert(result.data.message);
    }
  }
};
</script>
<style scoped>
.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
.v-btn--active::before {
  opacity: 0 !important;
}
</style>